import React, { useCallback, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import T from 'ecto-common/lib/lang/Language';

import AdminPage from 'js/components/AdminPage';

import SignalMappingPage from 'js/components/SignalMapping/SignalMappingPage';
import ProcessMapsPage from 'js/components/ProcessMaps/ProcessMapsPage';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import EditLocationOrEquipment from 'js/components/EditLocation/EditLocationOrEquipment';
import TenantDashboardsPage from '../Dashboards/TenantDashboardsPage';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { useCurrentNode } from 'ecto-common/lib/hooks/useCurrentNode';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

type AdminPageProps = {
  onTitleChanged?: (title: string[]) => void;
  selectedLocation: NodeV2ResponseModel;
};

const contentRenderFunctions: Record<
  string,
  (props: AdminPageProps) => JSX.Element
> = {
  dashboard: ({ onTitleChanged, selectedLocation }: AdminPageProps) => (
    <EditLocationOrEquipment
      onTitleChanged={onTitleChanged}
      selectedLocation={selectedLocation}
    />
  ),
  signalmapping: ({ onTitleChanged, selectedLocation }: AdminPageProps) => (
    <SignalMappingPage
      onTitleChanged={onTitleChanged}
      selectedLocation={selectedLocation}
    />
  ),
  dashboards: () => <TenantDashboardsPage />,
  processmaps: () => <ProcessMapsPage />
};

const Location = () => {
  const { nodeId, page: pageFromParams } = useParams<NodeParams>();
  const { tenantId } = useContext(TenantContext);

  const rootLevelNodes = useCommonSelector(
    (state) => state.general.rootLevelNodes
  );

  const { currentNode: selectedLocation } = useCurrentNode();
  const nodeListEmpty = rootLevelNodes?.length === 0;
  const page = nodeId === ROOT_NODE_ID ? 'dashboard' : pageFromParams;

  const onTitleChanged = useCallback((title: string[]) => {
    document.title = title.join(' | ');
  }, []);

  if (!selectedLocation && nodeId !== ROOT_NODE_ID) {
    if (nodeListEmpty) {
      return (
        <Navigate
          to={{
            pathname: `/${tenantId}/home/${ROOT_NODE_ID}/dashboard`
          }}
        />
      );
    }

    const content = (
      <ToolbarContentPage title={T.location.error.find} wrapContent>
        {T.location.error.find}
      </ToolbarContentPage>
    );

    return <AdminPage content={content} />;
  }

  const contentFunc = contentRenderFunctions[page];

  return (
    <AdminPage
      content={contentFunc && contentFunc({ onTitleChanged, selectedLocation })}
    />
  );
};

export default Location;
