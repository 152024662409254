import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import IntegrationAPIGen, {
  IntegrationPointResponse,
  IntegrationProxyResponse
} from 'ecto-common/lib/API/IntegrationAPIGen';
import _ from 'lodash';
import IntegrationPointTable from 'js/components/Integrations/IntegrationPointTable';
import { useAddOrEditIntegrationPointModal } from 'js/components/Integrations/IntegrationModels';
import IntegrationAdminAPIGen from 'ecto-common/lib/API/IntegrationAdminAPIGen';
import { useQueryClient } from '@tanstack/react-query';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';

type IntegrationOptionType = GenericSelectOption<string> & {
  integrationProxy: IntegrationProxyResponse;
};

const loadOptions = (
  contextSettings: ApiContextSettings,
  search: string,
  _loadedOptions: IntegrationOptionType[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additional: any
) => {
  return IntegrationAPIGen.IntegrationProxies.listIntegrationProxies
    .promise(
      contextSettings,
      {
        continuationToken: additional?.continuationToken,
        $orderby: 'name asc',
        $filter: search
          ? `contains(tolower(name), tolower('${search}'))`
          : undefined
      },
      null
    )
    .then((result) => {
      const options = _.map(result.items, (item) => ({
        label: item.name,
        value: item.id,
        integrationProxy: item
      }));

      const { continuationToken } = result;

      return Promise.resolve({
        options,
        hasMore: continuationToken != null,
        additional: {
          continuationToken
        }
      });
    });
};

type AddNodeToIntegrationPointModalProps = {
  nodeId: string;
  isOpen: boolean;
  onModalClose: () => void;
};

const AddNodeToIntegrationPointModal = ({
  isOpen,
  onModalClose,
  nodeId
}: AddNodeToIntegrationPointModalProps) => {
  const [integrationProxy, setIntegrationProxy] =
    useState<IntegrationProxyResponse>(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedIntegrationPoint, setSelectedIntegrationPoint] =
    useState<IntegrationPointResponse>(null);

  const clearSelectedPointId = useCallback(() => {
    setSelectedIntegrationPoint(null);
  }, []);

  useEffect(() => {
    setSelectedOption(null);
    setIntegrationProxy(null);
  }, [isOpen]);

  const { node } = useNode(nodeId);
  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const { mutate: addNodeToPoint, isPending: isAddingNodeToPoint } =
    IntegrationAdminAPIGen.IntegrationPoints.addNodeToIntegrationPoint.useMutation(
      { pointId: selectedIntegrationPoint?.id, nodeId: nodeId },
      {
        onSuccess: () => {
          toastStore.addSuccessToast(T.admin.integrations.nodeaddedtopoint);
          setSelectedIntegrationPoint(null);
          queryClient.invalidateQueries();
        },
        onError: useCallback(() => {
          toastStore.addErrorToast(T.common.unknownerror);
        }, [])
      }
    );

  const onChange = useCallback((e: IntegrationOptionType) => {
    setSelectedOption(e);
    setIntegrationProxy(e.integrationProxy);
  }, []);

  const { onAddIntegrationPoint, addIntegrationModelComponent } =
    useAddOrEditIntegrationPointModal(integrationProxy, nodeId);

  const onAddNodeToIntegrationPoint = useCallback(
    (point: IntegrationPointResponse) => {
      setSelectedIntegrationPoint(point);
    },
    []
  );

  const shouldDisableAddForIntegrationPoint = useCallback(
    (integrationPoint: IntegrationPointResponse) => {
      return integrationPoint.nodeIds.includes(nodeId);
    },
    [nodeId]
  );

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        onModalClose={onModalClose}
        headerIcon={Icons.Add}
        disableActionButton={integrationProxy == null}
        actionText={
          <>
            {' '}
            <Icons.Add /> {T.admin.integrations.addpoint}{' '}
          </>
        }
        onConfirmClick={onAddIntegrationPoint}
        cancelText={T.common.done}
        title={T.admin.integrations.addtopointtitle}
        large
      >
        <KeyValueSelectableInput<IntegrationOptionType>
          keyText={T.admin.integrations.integrationproxyname}
          value={selectedOption}
          onChange={onChange}
          placeholder={T.admin.integrations.integrationproxyname}
          loadOptions={(search, loadedOptions, additional) =>
            loadOptions(contextSettings, search, loadedOptions, additional)
          }
          options={undefined}
        />

        <IntegrationPointTable
          onAdd={onAddNodeToIntegrationPoint}
          shouldDisableAdd={shouldDisableAddForIntegrationPoint}
          integrationProxyId={integrationProxy?.id}
          key={integrationProxy?.id}
        />
      </ActionModal>
      {addIntegrationModelComponent}
      <ActionModal
        title={T.admin.integrations.addtopointtitle}
        headerIcon={Icons.Add}
        isLoading={isAddingNodeToPoint}
        onConfirmClick={() => addNodeToPoint({})}
        onModalClose={clearSelectedPointId}
        isOpen={selectedIntegrationPoint != null}
      >
        {T.format(
          T.admin.integrations.addnodetopointformat,
          node?.name ?? '',
          selectedIntegrationPoint?.name ?? ''
        )}
      </ActionModal>
    </>
  );
};

AddNodeToIntegrationPointModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func
};

export default React.memo(AddNodeToIntegrationPointModal);
