import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import styles from 'js/components/EditLocation/CreateLocationDialog.module.css';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import LocationTreeView, {
  useNodeTree
} from 'ecto-common/lib/LocationTreeView/LocationTreeView';
import {
  nodeIsEnergyManager,
  nodeIsEquipment,
  useNode
} from 'ecto-common/lib/hooks/useCurrentNode';

interface SelectDeviceDialogProps {
  onModalClose: () => void;
  isOpen: boolean;
  onChangedSelectedEnergyManager(em: NodeV2ResponseModel): void;
  selectedEnergyManagerEquipmentId?: string;
  onConfirmClick(): void;
  initialNode?: NodeV2ResponseModel;
}

function emFilter(node: NodeV2ResponseModel) {
  return !nodeIsEquipment(node) || nodeIsEnergyManager(node);
}

const SelectDeviceDialog = ({
  onModalClose,
  isOpen,
  onConfirmClick,
  initialNode,
  onChangedSelectedEnergyManager,
  selectedEnergyManagerEquipmentId
}: SelectDeviceDialogProps) => {
  const params = useParams<NodeParams>();
  const nodeId = useAdminSelector((state) => state.general.nodeId);
  let focusedId = null;

  if (initialNode) {
    focusedId = selectedEnergyManagerEquipmentId || initialNode.nodeId;
  }

  const { node: equipmentNode } = useNode(selectedEnergyManagerEquipmentId);
  const { node: parent } = useNode(equipmentNode?.parentId);

  const selectedIds = useMemo(() => {
    if (parent != null) {
      return [selectedEnergyManagerEquipmentId];
    }

    return _.compact([selectedEnergyManagerEquipmentId || nodeId]);
  }, [nodeId, parent, selectedEnergyManagerEquipmentId]);

  const {
    nodes,
    renderRowSideIcons,
    nodeHasChildren,
    onExpandedStateChange,
    expanded
  } = useNodeTree(_.head(selectedIds), _.noop, emFilter);

  const _onSelectedChanged = useCallback(
    (selectedId: string) => {
      const em = nodes.find((n) => n.nodeId === selectedId);
      if (em) {
        if (em.nodeId === selectedEnergyManagerEquipmentId) {
          onChangedSelectedEnergyManager(null);
        } else {
          onChangedSelectedEnergyManager(em);
        }
      }
    },
    [nodes, onChangedSelectedEnergyManager, selectedEnergyManagerEquipmentId]
  );

  return (
    <Modal
      className={styles.deviceDialog}
      onModalClose={onModalClose}
      isOpen={isOpen}
    >
      <ModalHeader titleIcon={Icons.Connect}>
        {T.admin.selectdevice.title}
      </ModalHeader>

      <ModalBody className={styles.deviceSettingBody}>
        <LocationTreeView
          nodeList={nodes}
          onChangeSelectedState={_onSelectedChanged}
          selectedIds={selectedIds}
          renderRowSideIcons={renderRowSideIcons}
          expanded={expanded}
          onExpandedStateChange={onExpandedStateChange}
          nodeHasChildren={nodeHasChildren}
          focusedId={focusedId ?? params?.nodeId}
        />
      </ModalBody>
      <ModalFooter>
        <LocalizedButtons.Done onClick={onConfirmClick} />
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectDeviceDialog);
