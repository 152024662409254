import React, { SetStateAction, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import { EditIcon } from 'ecto-common/lib/Icon';

import styles from 'js/components/EditLocation/LocationForm.module.css';
import SelectEquipment from 'ecto-common/lib/SelectEquipment/SelectEquipment';

interface EditLocationParentDialogProps {
  isOpen: boolean;
  selectedParentId: string;
  onParentSelectedChanged(parentId: string, isSelected: boolean): void;
  onParentsEditCancelled(): void;
  onParentsEditDone(): void;
}

const EditLocationParentDialog = ({
  isOpen,
  selectedParentId,
  onParentSelectedChanged,
  onParentsEditCancelled,
  onParentsEditDone
}: EditLocationParentDialogProps) => {
  const selectedIds = useMemo(() => {
    return _.compact([selectedParentId]);
  }, [selectedParentId]);

  const setSelectedIds = useCallback(
    (action: SetStateAction<string[]>) => {
      if (typeof action === 'function') {
        const newState = (action as (prevState: string[]) => string[])([
          selectedParentId
        ]);
        onParentSelectedChanged(newState[0], true);
      } else {
        onParentSelectedChanged((action as string[])[0], true);
      }
    },
    [onParentSelectedChanged, selectedParentId]
  );

  return (
    <Modal
      className={styles.editParentsModal}
      onModalClose={onParentsEditCancelled}
      isOpen={isOpen}
    >
      <ModalHeader titleIcon={EditIcon}>
        {T.admin.editlocation.editparents.title}
      </ModalHeader>

      <ModalBody className={styles.editParentBody}>
        <div className={classNames(styles.treeSelector)}>
          <SelectEquipment
            setSelectedIds={setSelectedIds}
            selectedIds={selectedIds}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <LocalizedButtons.Save onClick={onParentsEditDone} />

        <LocalizedButtons.Cancel onClick={onParentsEditCancelled} />
      </ModalFooter>
    </Modal>
  );
};

export default EditLocationParentDialog;
