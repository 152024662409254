import { createAction } from 'ecto-common/lib/utils/actionUtils';
import UUID from 'uuidjs';
import APIGen, {
  CreateBuildingByTemplateDataResponseModel,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AdminRootState } from 'js/reducers/storeAdmin';
import { QueryClient } from '@tanstack/react-query';
import _ from 'lodash';
export const SET_PROVISIONING_DEVICE_DATA_CHANGED =
  'SET_PROVISIONING_DEVICE_DATA_CHANGED';

export const ProvisioningActions = {
  notifyDeviceDataChanged: createAction(SET_PROVISIONING_DEVICE_DATA_CHANGED)
};

export enum CreateError {
  BUILDING_NAMING_CONFLICT = 'BUILDING_NAMING_CONFLICT',
  SITE_NAMING_CONFLICT = 'SITE_NAMING_CONFLICT',
  MISSING_CONNECTION = 'MISSING_CONNECTION',
  GENERIC = 'GENERIC'
}

export const instantiateBuildingTemplate = async (
  contextSettings: ApiContextSettings,
  node: NodeV2ResponseModel,
  { equipmentTemplateForm }: AdminRootState
): Promise<CreateBuildingByTemplateDataResponseModel[]> => {
  const {
    equipmentTemplateGroupId,
    equipmentTemplateInstanceOverrides,
    connectionModbusConfigOverride,
    existingEnergyManagerDeviceId
  } = equipmentTemplateForm;

  const templateData = {
    nodeId: node.nodeId,
    equipmentTemplateGroupId,
    connectionModbusConfigOverride,
    equipmentTemplateInstanceOverrides,
    deviceId: existingEnergyManagerDeviceId ?? UUID.generate()
  };

  const instructions =
    await APIGen.AdminBuildings.getAddOrUpdateBuildingsByTemplates.promise(
      contextSettings,
      [templateData],
      null
    );

  return await APIGen.AdminBuildings.addOrUpdateBuildingsByTemplates.promise(
    contextSettings,
    instructions,
    null
  );
};

export const updateNodeTreeIncrementallyFromDelete = (
  deletedNodeId: string,
  deletedNodeParentId: string | null,
  queryClient: QueryClient,
  contextSettings: ApiContextSettings
) => {
  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: _.compact([deletedNodeParentId])
    })
  });

  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: [deletedNodeId]
    })
  });

  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodeAndAncestors.path(contextSettings, {
      nodeId: deletedNodeId
    })
  });
};

export function resetCacheForNode(
  contextSettings: ApiContextSettings,
  nodeId: string,
  parentId: string,
  queryClient: QueryClient
) {
  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: [parentId]
    })
  });

  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodeAndAncestors.path(contextSettings, {
      nodeId: nodeId
    })
  });

  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
  });
}

export function patchNodes(
  contextSettings: ApiContextSettings,
  changedNodes: NodeV2ResponseModel[],
  queryClient: QueryClient
) {
  for (const node of changedNodes) {
    resetCacheForNode(contextSettings, node.nodeId, node.parentId, queryClient);
  }
}

export const updateNodeTreeIncrementally = (
  contextSettings: ApiContextSettings,
  parentNodeId: string | null,
  queryClient: QueryClient
) => {
  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: _.compact([parentNodeId])
    })
  });
};

export const updateNodeTreeAfterNodeAddedOrUpdated = (
  contextSettings: ApiContextSettings,
  parentId: string,
  queryClient: QueryClient
) => {
  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: [parentId]
    })
  });
};
