import React, { useContext, useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';

import useComfortTools from 'js/components/ManageEquipment/EditEquipment/Comfort/useComfortTools';
import EditComfortTool from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/EditComfortTool';
import EditLinearOptimizationTool from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/EditLinearOptimizationTool';
import EditPowerControlTool from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/EditPowerControlTool';
import PowerDeltaTool from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/PowerDeltaTool';
import BatteryChargingThresholdTool from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/BatteryChargingThresholdTool';
import {
  ToolSignalProviders,
  ToolSignalProviderToResourceType,
  ToolSignalProviderTranslations
} from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import { useTool as useToolImpl } from 'js/components/ManageEquipment/EditEquipment/Util/useTool';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import NetmoreTool from 'js/components/ManageEquipment/EditEquipment/Netmore/NetmoreTool';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { EditStateData } from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { EditToolDialogProps } from 'js/components/ManageEquipment/EditEquipment/Util/EditToolDialog';
export interface ToolProvider {
  nodeId?: string;
}

export type AdminToolImplementationType<ObjectType extends object> = {
  type: ToolSignalProviders;
  name: string;
  accessRights: string[];
  allowDelete: boolean;
  allowDeploy: boolean;
  model: ModelDefinition<ObjectType>[];
  emptyInput:
    | Partial<ObjectType>
    | ((eq: NodeV2ResponseModel) => Partial<ObjectType>);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  save: (
    contextSettings: ApiContextSettings,
    args: ObjectType[],
    abortSignal: AbortSignal
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
  delete: (
    contextSettings: ApiContextSettings,
    providerIds: string[],
    abortSignal: AbortSignal
  ) => Promise<void>;
  load: (
    contextSettings: ApiContextSettings,
    equipmentIds: string[],
    abortSignal: AbortSignal
  ) => Promise<ToolProvider[]>;
  helpPath?: string;
  idKey?: string;
  includeDeviceId?: boolean;
  dialog?: React.FC<EditToolDialogProps<ObjectType>>;
  available?: (
    tool: AdminToolImplementationType<ObjectType>,
    nodeId: string,
    equipmentId: string
  ) => boolean;
};

export type AdminToolType = {
  name?: string;
  type: ToolSignalProviders;
  allowDelete: boolean;
  allowDeploy: boolean;
  component?: React.ReactNode;
  data?: unknown;
};

type UseEquipmentToolsResult = {
  currentTools: AdminToolType[];
  availableTools: AdminToolType[];
  loadingTools: boolean;
};

/**
 * Determines the current active tools and the available tools.
 * @returns {{currentTools, availableTools}}
 * currentTools - array of active tools in format { type, name, data, onEdit }
 * availableTools - array of available tools for the current equipment
 * loadingTools - If tools information is still loading
 */
export const useEquipmentTools = (
  nodeId: string,
  equipmentId: string,
  editStates: Record<string, EditStateData>,
  setEditState: (toolType: ToolSignalProviders, value: EditStateData) => void
): UseEquipmentToolsResult => {
  const powerControls = useAdminSelector(
    (state) => state.editEquipmentTools.powerControls
  );
  const linearOptimisations = useAdminSelector(
    (state) => state.editEquipmentTools.linearOptimisations
  );
  const [currentComfortTool, isLoadingComfortTool, reloadComfortTools] =
    useComfortTools(nodeId, equipmentId);

  const useTool = <ToolType extends object>(
    tool: AdminToolImplementationType<ToolType>
  ) => {
    return useToolImpl({
      tool,
      nodeId,
      equipmentId,
      editStates,
      setEditState
    });
  };

  const { tenantResources } = useContext(TenantContext);
  const powerDeltaTool = useTool(PowerDeltaTool);
  const electricityPeakShavingTool = useTool(BatteryChargingThresholdTool);
  const netmoreTool = useTool(NetmoreTool);

  // tools that uses useTool
  const newTools = useMemo(
    () => [powerDeltaTool, electricityPeakShavingTool, netmoreTool],
    [powerDeltaTool, electricityPeakShavingTool, netmoreTool]
  );

  const powerControl = _.head(powerControls);
  const linearOptimisation = _.head(linearOptimisations);

  return useMemo(() => {
    // All available tools
    let allTools: AdminToolType[] = [
      {
        type: ToolSignalProviders.POWER_CONTROL,
        allowDelete: false,
        allowDeploy: false,
        component: (
          <EditPowerControlTool
            key={ToolSignalProviders.POWER_CONTROL}
            editState={editStates[ToolSignalProviders.POWER_CONTROL]}
            setEditState={setEditState}
          />
        )
      },
      {
        type: ToolSignalProviders.LINEAR_OPTIMIZATION,
        allowDelete: false,
        allowDeploy: false,
        component: (
          <EditLinearOptimizationTool
            key={ToolSignalProviders.LINEAR_OPTIMIZATION}
            editState={editStates[ToolSignalProviders.LINEAR_OPTIMIZATION]}
            setEditState={setEditState}
          />
        )
      },
      {
        type: ToolSignalProviders.COMFORT,
        allowDelete: true,
        allowDeploy: true,
        component: (
          <EditComfortTool
            key={ToolSignalProviders.COMFORT}
            nodeId={nodeId}
            equipmentId={equipmentId}
            editState={editStates[ToolSignalProviders.COMFORT]}
            setEditState={setEditState}
            reloadTools={reloadComfortTools}
          />
        )
      },
      ...newTools
    ];

    allTools = allTools
      // Remove tools that the user do not have access to
      .filter((tool) => {
        return _.find(tenantResources, (tenantResource) => {
          const resourcesType = ToolSignalProviderToResourceType?.[tool?.type];
          if (_.isEmpty(resourcesType)) {
            return true;
          }
          return _.includes(resourcesType, tenantResource.name);
        });
      })
      // add translation to 'name'
      .map((tool) => ({
        ...tool,
        name: ToolSignalProviderTranslations[tool.type]
      }));

    // Add current tools
    const currentTools = _.reduce(
      allTools,
      (result, tool) => {
        if (powerControl && tool.type === ToolSignalProviders.POWER_CONTROL) {
          result.push({
            ...tool,
            name: T.format(
              T.admin.equipment.powercontrolformat,
              T.admin.equipment.powercontroltype[
                powerControl.powerControlType.toLowerCase() as keyof typeof T.admin.equipment.powercontroltype
              ] +
                ' ' +
                powerControl.algorithmType
            ),
            data: powerControl
          });
        } else if (
          linearOptimisation &&
          tool.type === ToolSignalProviders.LINEAR_OPTIMIZATION
        ) {
          result.push({ ...tool, data: linearOptimisation });
        } else if (
          currentComfortTool &&
          tool.type === ToolSignalProviders.COMFORT
        ) {
          result.push({ ...tool, data: currentComfortTool });
        }

        for (const newTool of newTools) {
          if (newTool.isActive(tool)) {
            result.push({ ...tool, data: newTool.currentTool });
          }
        }
        return result;
      },
      []
    );

    // Remove types that are in currentTools
    const availableTools = allTools.filter(
      ({ type }) => !_.some(currentTools, { type })
    );

    const loadingTools = isLoadingComfortTool || _.some(newTools, 'isLoading');

    return { currentTools, availableTools, loadingTools };
  }, [
    isLoadingComfortTool,
    currentComfortTool,
    reloadComfortTools,
    editStates,
    setEditState,
    equipmentId,
    nodeId,
    newTools,
    powerControl,
    linearOptimisation,
    tenantResources
  ]);
};
