import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import { NavLink } from 'react-router-dom';

import DashboardEditor from 'ecto-common/lib/DashboardEditor/DashboardEditor';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  DashboardFile,
  InitialDashboardFileData
} from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import DashboardMenu from 'ecto-common/lib/DashboardEditor/DashboardMenu';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import TimeRangeContext from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import styles from './EditDashboardPage.module.css';
import useTimeRangeSelector from 'ecto-common/lib/Dashboard/context/useTimeRangeSelector';
import DashboardDataContext, {
  useDashboardDataFromRedux
} from 'ecto-common/lib/hooks/DashboardDataContext';
import { SET_CURRENT_NODE } from 'ecto-common/lib/actions/actionTypes';
import HelpPaths from 'ecto-common/help/tocKeys';
import { useAdminDispatch } from 'js/reducers/storeAdmin';
import useUndoHistory from 'ecto-common/lib/hooks/useUndoHistory';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';
import { UseQueryResult, useQueryClient } from '@tanstack/react-query';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import { NestedTenantContainer } from 'ecto-common/lib/Application/TenantContainer';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { usePromptMessage } from 'ecto-common/lib/hooks/useBlockerListener';
import PresentationAPIGen from 'ecto-common/lib/API/PresentationAPIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { applyDashboardMigrations } from 'ecto-common/lib/Dashboard/migrations/migrations';
import { TEMPLATE_ADMIN_ID } from 'ecto-common/lib/constants';
import PreviewNodeToolbarItems from 'ecto-common/lib/PreviewNodeToolbarItems/PreviewNodeToolbarItems';

type Dashboard = {
  id?: string;
  name?: string;
  description?: string;
  data: object;
};

const EditTenantDashboardPage = ({
  dashboardId,
  parentLink,
  parentName
}: {
  dashboardId?: string;
  parentName: string;
  parentLink: string;
}) => {
  const navigate = useNavigate();
  const [panelEditItem, setPanelEditItem] = useState<DashboardPanel>(null);
  const [previewTenantId, setPreviewTenantId] = useSearchParamState(
    'preview-tenant-id',
    null
  );

  const [previewNodeId, setPreviewNodeId] = useSearchParamState(
    'preview-node-id',
    null
  );

  const enums = useCommonSelector((state) => state.general.enums);
  const signalTypesNameMap = useCommonSelector(
    (state) => state.general.signalTypesNameMap
  );

  const parseAndMigrate = useCallback(
    (data: { data: string }) => {
      if (!_.isEmpty(data.data)) {
        try {
          const parsedData = JSON.parse(data.data);
          const migratedData = applyDashboardMigrations(
            parsedData as DashboardFile,
            enums,
            signalTypesNameMap
          );
          return { ...data, data: migratedData };
        } catch (e) {
          console.error(e);
        }
      }
      return data;
    },
    [enums, signalTypesNameMap]
  );

  const [dashboard, setDashboard] = useState<Dashboard>(null);

  const dashboardFile = useMemo(
    () => ({ ...InitialDashboardFileData, ...dashboard?.data }),
    [dashboard?.data]
  );

  const [initialDashboards, setInitialDashboards] = useState<Dashboard[]>(null);
  const onUpdateFromUndo = useCallback((newDashboardData: Dashboard) => {
    setDashboard(newDashboardData);
    setHasChanges(true);
  }, []);

  const [
    hasUndo,
    hasRedo,
    pushDashboardHistory,
    undoDashboardHistory,
    redoDashboardHistory
  ] = useUndoHistory<Dashboard>(initialDashboards, onUpdateFromUndo);

  const [hasChanges, setHasChanges] = useState(false);
  const [triggerAddPanel, setTriggerAddPanel] = useReloadTrigger();

  const onDelete = useCallback(() => {
    setHasChanges(false);

    _.defer(() => {
      navigate(parentLink);
    });
  }, [navigate, parentLink]);

  const onEditedDashboard = useCallback(
    (editedDashboard: Dashboard) => {
      pushDashboardHistory(editedDashboard);
      setDashboard(editedDashboard);
      setHasChanges(true);
    },
    [pushDashboardHistory, setDashboard]
  );

  const dashboardQuery =
    PresentationAPIGen.TenantDashboards.getTenantDashboard.useQuery(
      {
        id: dashboardId
      },
      {
        retry: false,
        meta: { errorString: T.admin.dashboards.loadobject.failure },
        select: parseAndMigrate as (data: { data: string }) => unknown
      }
    ) as UseQueryResult<Dashboard>;

  useEffect(() => {
    if (dashboardQuery.data != null) {
      setInitialDashboards([_.cloneDeep(dashboardQuery.data)]);
      setDashboard(dashboardQuery.data);
    }
  }, [dashboardQuery.data]);

  const error: string = dashboardQuery.isError
    ? T.admin.dashboards.loadobject.failure
    : null;

  const queryClient = useQueryClient();
  const { tenantId, contextSettings } = useContext(TenantContext);

  const saveDashboardMutation =
    PresentationAPIGen.TenantDashboards.updateTenantDashboard.useMutation(
      {
        id: dashboardId
      },
      {
        meta: { errorString: T.admin.dashboards.save.failure },
        onSuccess: () => {
          setHasChanges(false);
          toastStore.addSuccessToast(T.admin.dashboards.change.success);
          queryClient.invalidateQueries({
            queryKey:
              PresentationAPIGen.TenantDashboards.listTenantDashboards.path(
                contextSettings
              )
          });
        }
      }
    );

  const onSaveDashboardFile = useCallback(() => {
    const savedDashboard = {
      ...dashboard,
      data: JSON.stringify(dashboard.data)
    };
    saveDashboardMutation.mutate(savedDashboard);
  }, [dashboard, saveDashboardMutation]);

  const [timeRange, timeRangeComponent] = useTimeRangeSelector();
  const showingDialog = panelEditItem != null;

  const currentTenantId = previewTenantId ?? tenantId;

  const menu = (
    <>
      <PreviewNodeToolbarItems
        previewNodeId={previewNodeId}
        setPreviewNodeId={setPreviewNodeId}
        previewTenantId={currentTenantId}
        setPreviewTenantId={
          tenantId === TEMPLATE_ADMIN_ID ? setPreviewTenantId : null
        }
      />
      <ToolbarItem>
        <DashboardMenu
          enableSelect={false}
          isLoadingDashboards={dashboardQuery.isPending}
          dashboard={dashboard}
          showAddNewDashboard={false}
          onAddPanel={setTriggerAddPanel}
          onDashboardUpdated={onEditedDashboard}
          onDeleteDashboard={onDelete}
          onSave={onSaveDashboardFile}
          hasChanges={hasChanges}
          undo={undoDashboardHistory}
          enableUndo={hasUndo && !showingDialog}
          redo={redoDashboardHistory}
          enableRedo={hasRedo && !showingDialog}
          disableEditing={dashboard == null || dashboardFile == null}
        />
      </ToolbarItem>
      <ToolbarFlexibleSpace />
      <ToolbarItem className={styles.timerange}>
        {timeRangeComponent}
      </ToolbarItem>
    </>
  );

  const onDashboardFileChanged = useCallback(
    (newDashboardFile: DashboardFile) => {
      pushDashboardHistory({ ...dashboard, data: newDashboardFile });
      setHasChanges(true);
    },
    [dashboard, pushDashboardHistory]
  );

  const title = (
    <>
      <NavLink to={parentLink}>{parentName}</NavLink>
      &nbsp;&gt;&nbsp;{dashboard?.name}
    </>
  );

  const [locationPickerOpen, setLocationPickerOpen] = useState(false);

  useEffect(() => {
    _.defer(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }, [locationPickerOpen]);

  const dispatch = useAdminDispatch();

  const setNode = useCallback(
    (nodeId: string) => {
      dispatch({ type: SET_CURRENT_NODE, payload: { nodeId } });
    },
    [dispatch]
  );

  useEffect(() => {
    if (dashboard) {
      document.title = `${parentName} > ${dashboard?.name}`;
    } else {
      document.title = parentName;
    }
  }, [dashboard, parentName]);

  const nodeId = previewNodeId;

  const reduxDashboardData = useDashboardDataFromRedux();
  const dashboardDataValue = useMemo(
    () => ({ nodeId, setNode, ...reduxDashboardData }),
    [nodeId, setNode, reduxDashboardData]
  );

  usePromptMessage(T.admin.form.unsavedstate, hasChanges);

  return (
    <ToolbarContentPage
      title={title}
      wrapContent={false}
      toolbarItems={menu}
      showLocationPicker={false}
      locationPickerOpen={locationPickerOpen}
      setLocationPickerOpen={setLocationPickerOpen}
      disablePageChange
      helpPath={HelpPaths.docs.admin.manage.dashboards}
    >
      <NestedTenantContainer tenantId={currentTenantId}>
        <DashboardDataContext.Provider value={dashboardDataValue}>
          <TimeRangeContext.Provider value={timeRange}>
            <DashboardEditor
              panelEditItem={panelEditItem}
              setPanelEditItem={setPanelEditItem}
              dashboardFile={dashboardFile}
              setDashboardFile={(newData) =>
                setDashboard((oldDashboard) => ({
                  ...oldDashboard,
                  data: newData
                }))
              }
              isLoading={
                dashboardQuery.isPending || saveDashboardMutation.isPending
              }
              triggerAddPanel={triggerAddPanel}
              error={error}
              onDashboardFileChanged={onDashboardFileChanged}
            />
          </TimeRangeContext.Provider>
        </DashboardDataContext.Provider>
      </NestedTenantContainer>
    </ToolbarContentPage>
  );
};

export default React.memo(EditTenantDashboardPage);
