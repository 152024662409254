import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
  ChangeEventHandler
} from 'react';
import styles from './ManageMeteorology.module.css';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';

import T from 'ecto-common/lib/lang/Language';
import DraggableMarkerMap from 'ecto-common/lib/Map/DraggableMarkerMap';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { NavLink } from 'react-router-dom';

import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import staticDataTableStyles from 'ecto-common/lib/StaticDataTable/StaticDataTable.module.css';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import HelpPaths from 'ecto-common/help/tocKeys';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import APIGen, {
  AddGeographicalPointRequestModel,
  GeographicalPointResponseModel,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ItemPageParams } from 'js/utils/routeConstants';
import { usePromptMessage } from 'ecto-common/lib/hooks/useBlockerListener';
import { nodeIsEquipment } from 'ecto-common/lib/hooks/useCurrentNode';
import LocationTreeView, {
  useNodeTree
} from 'ecto-common/lib/LocationTreeView/LocationTreeView';

function nodeFilter(node: NodeV2ResponseModel) {
  return !nodeIsEquipment(node);
}

const EditMeteorologicalPoint = () => {
  const params = useParams<ItemPageParams>();

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [selectedPoint, setSelectedPoint] =
    useState<GeographicalPointResponseModel>(null);
  const { tenantId } = useContext(TenantContext);
  const prevSelectedPointRef = useRef(null);

  const getQuery = APIGen.Meteorology.getGeographicalPoints.useQuery({});

  if (selectedPoint == null && getQuery.data) {
    const _selectedPoint = _.find(getQuery.data, { id: params.itemId });

    if (_selectedPoint) {
      prevSelectedPointRef.current = _selectedPoint;
      setSelectedPoint(_selectedPoint);
    }
  }

  const updateMutation =
    APIGen.Meteorology.addOrUpdateGeographicalPoint.useMutation({
      onSuccess: () => {
        setHasUnsavedChanges(false);
        toastStore.addSuccessToast(T.admin.requests.updateweatherpoint.success);
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.requests.updateweatherpoint.failure);
      }
    });

  const savePoint = useCallback(() => {
    // Cast due to response model having optional fields
    updateMutation.mutate({
      ...selectedPoint,
      id: params?.itemId
    } as AddGeographicalPointRequestModel);
  }, [updateMutation, selectedPoint, params?.itemId]);

  useEffect(() => {
    setHasUnsavedChanges(
      !_.isEqual(prevSelectedPointRef.current, selectedPoint)
    );
  }, [selectedPoint]);

  const onSelectedPointChange = useCallback(
    (newValue: Partial<GeographicalPointResponseModel>) => {
      setSelectedPoint((prevState) => ({ ...prevState, ...newValue }));
    },
    []
  );

  const onSelectedChanged = useCallback(
    (nodeId: string, isSelected: boolean) => {
      if (nodeId.startsWith(ROOT_NODE_ID)) {
        return;
      }

      let _nodeIds;

      if (isSelected) {
        _nodeIds = _.uniq(selectedPoint.nodeIds.concat([nodeId]));
      } else {
        _nodeIds = _.filter(selectedPoint.nodeIds, (x) => x !== nodeId);
      }

      onSelectedPointChange({ nodeIds: _nodeIds });
    },
    [onSelectedPointChange, selectedPoint]
  );

  const onCoordinateChanged = useCallback(
    (latitude: number, longitude: number) => {
      onSelectedPointChange({ longitude, latitude });
    },
    [onSelectedPointChange]
  );

  const center = {
    lat: selectedPoint?.latitude,
    lng: selectedPoint?.longitude
  };

  const toolbarItems = [
    <ToolbarFlexibleSpace key="space" />,
    <ToolbarItem key="save">
      <LocalizedButtons.Save
        disabled={!hasUnsavedChanges || selectedPoint == null}
        onClick={savePoint}
      />
    </ToolbarItem>
  ];

  const onNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onSelectedPointChange({ name: event.target.value });
    },
    [onSelectedPointChange]
  );

  const isLoading = updateMutation.isPending || getQuery.isLoading;

  const onSelectLocationFromSearch = useCallback(
    ({ lat, lng }: { lat: number; lng: number }) => {
      onSelectedPointChange({ longitude: lng, latitude: lat });
    },
    [onSelectedPointChange]
  );

  usePromptMessage(T.admin.form.unsavedstate, hasUnsavedChanges);

  useEffect(() => {
    if (selectedPoint) {
      document.title = `${T.admin.meteorology.overview.header} > ${selectedPoint.name}`;
    } else {
      document.title = T.admin.meteorology.overview.header;
    }
  }, [selectedPoint]);

  const {
    nodes,
    renderRowSideIcons,
    nodeHasChildren,
    onExpandedStateChange,
    expanded
  } = useNodeTree(_.head(selectedPoint?.nodeIds), _.noop, nodeFilter);

  return (
    <LoadingContainer isLoading={isLoading} showSpinner>
      <ToolbarContentPage
        showLocationPicker={false}
        toolbarItems={toolbarItems}
        title={
          <>
            <NavLink to={`/${tenantId}/meteorology`}>
              {T.admin.meteorology.overview.header}
            </NavLink>
            &nbsp;&gt;&nbsp;{selectedPoint && selectedPoint.name}
          </>
        }
        helpPath={HelpPaths.docs.admin.manage.weather}
      >
        {selectedPoint && (
          <div className={styles.map}>
            <DraggableMarkerMap
              onCoordinateChanged={onCoordinateChanged}
              initialLatitude={center.lat}
              initialLongitude={center.lng}
              initialZoom={16}
              searchable
              onSelectLocationFromSearch={onSelectLocationFromSearch}
            />
          </div>
        )}

        {selectedPoint && (
          <table
            className={classNames(
              staticDataTableStyles.staticDataTable,
              styles.inputTable
            )}
          >
            <tbody>
              <tr>
                <td>{T.admin.meteorology.input.name}</td>

                <td className={styles.valueColumn}>
                  <TextInput
                    placeholder={T.admin.meteorology.input.name}
                    value={selectedPoint?.name}
                    onChange={onNameChange}
                  />
                </td>
              </tr>

              <tr>
                <td className={styles.topAlignedRow}>
                  {T.admin.meteorology.locations}
                </td>

                <td className={styles.valueColumn}>
                  <div>
                    <LocationTreeView
                      multiSelect
                      selectedIds={selectedPoint?.nodeIds}
                      className={styles.treeContainer}
                      onChangeSelectedState={onSelectedChanged}
                      nodeList={nodes}
                      renderRowSideIcons={renderRowSideIcons}
                      expanded={expanded}
                      onExpandedStateChange={onExpandedStateChange}
                      nodeHasChildren={nodeHasChildren}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </ToolbarContentPage>
    </LoadingContainer>
  );
};

export default EditMeteorologicalPoint;
