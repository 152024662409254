import React, { Fragment } from 'react';
import _ from 'lodash';

import styles from '../DialogStyles.module.css';

import {
  ModbusBoolInput,
  ModbusNumberInput,
  ModbusSelect,
  ModbusSignalTypeInput,
  SectionHeader,
  SectionItem
} from './ModbusInputComponents';

import T from 'ecto-common/lib/lang/Language';

import { modbusModeToConfigType } from 'js/components/ModbusLayout/ModbusEditUtils';

import {
  ModbusByteorderTranslations,
  ModbusDataTypeExpanded,
  ModbusDataTypeTranslations,
  ModbusReadwriteTypeTranslations,
  ModbusTypeTranslations,
  SignalModbusConfigTemplateWithBitmaskResponseModel
} from 'js/components/ModbusLayout/ModbusTypes';
import { useAnnotatedSignals } from 'ecto-common/lib/hooks/useAnnotatedSignals';
import {
  ConnectionResponseModel,
  SignalModbusType
} from 'ecto-common/lib/API/APIGen';
import { AdminEquipmentSignalWithConfigType } from 'js/components/ManageEquipment/EditEquipment/Util/editEquipmentUtil';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';

interface ModbusSelectableSignalsProps {
  name: string;
  title: string;
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
  signals: AdminEquipmentSignalWithConfigType[];
}

export const ModbusSelectableSignals = ({
  name,
  title,
  modbus,
  onChangeProperty,
  signals
}: ModbusSelectableSignalsProps) => {
  const annotatedSignals = useAnnotatedSignals(signals);
  const selectValue = _.get(modbus, name);

  return (
    <SectionItem title={title}>
      <select
        value={selectValue}
        onChange={(e) => onChangeProperty(name, e.target.value)}
      >
        <option key={0} value={null} />
        {_.map(annotatedSignals, ({ signalId, formattedTypeName }, index) => {
          return (
            <option key={index + 1} value={signalId}>
              {formattedTypeName}
            </option>
          );
        })}
      </select>
    </SectionItem>
  );
};

interface SignalInputProps {
  signalIdName: string;
  signalTypeName: string;
  title: string;
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
  signals?: AdminEquipmentSignalWithConfigType[];
}

const SignalInput = ({
  signalIdName,
  signalTypeName,
  title,
  modbus,
  onChangeProperty,
  signals
}: SignalInputProps) => {
  if (signals == null) {
    return (
      <ModbusSignalTypeInput
        name={signalTypeName}
        title={title}
        modbus={modbus}
        onChangeProperty={onChangeProperty}
      />
    );
  }

  return (
    <ModbusSelectableSignals
      name={signalIdName}
      title={title}
      modbus={modbus}
      onChangeProperty={onChangeProperty}
      signals={signals}
    />
  );
};

interface WatchDogSectionProps {
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
  signals?: AdminEquipmentSignalWithConfigType[];
}

export const WatchDogSection = ({
  modbus,
  signals,
  onChangeProperty
}: WatchDogSectionProps) => {
  return (
    <div className={styles.gridFlow}>
      <ModbusBoolInput
        name="signalModbusWatchdog"
        title={T.admin.modbussignal.watchdog.title}
        modbus={modbus}
        onChangeProperty={onChangeProperty}
      >
        <ModbusNumberInput
          name="signalModbusWatchdog.period"
          title={T.admin.modbussignal.watchdog.period}
          modbus={modbus}
          onChangeProperty={onChangeProperty}
        />
        <ModbusNumberInput
          name="signalModbusWatchdog.high"
          title={T.admin.modbussignal.watchdog.high}
          modbus={modbus}
          onChangeProperty={onChangeProperty}
        />
        <SignalInput
          signalTypeName="signalModbusWatchdog.alarmSignalTypeId"
          signalIdName="signalModbusWatchdog.alarmSignalId"
          title={T.admin.modbussignal.watchdog.alarm}
          modbus={modbus}
          onChangeProperty={onChangeProperty}
          signals={signals}
        />
        <SignalInput
          signalTypeName="signalModbusWatchdog.enableSignalTypeId"
          signalIdName="signalModbusWatchdog.enableSignalId"
          title={T.admin.modbussignal.watchdog.enablesignal}
          modbus={modbus}
          onChangeProperty={onChangeProperty}
          signals={signals}
        />
      </ModbusBoolInput>
    </div>
  );
};

interface DelaySectionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
}

export const DelaySection = ({
  onChangeProperty,
  modbus
}: DelaySectionProps) => {
  return (
    <>
      <div className={styles.gridFlow}>
        <SectionHeader title={T.admin.modbussignal.delay} />

        <ModbusNumberInput
          name="delayDb"
          title={T.admin.modbussignal.delaydb}
          modbus={modbus}
          onChangeProperty={onChangeProperty}
          placeholder={T.admin.modbussignal.connectiondefault}
        />
      </div>

      <div className={styles.gridFlow}>
        <ModbusNumberInput
          name="delayModbus"
          title={T.admin.modbussignal.delaymodbus}
          modbus={modbus}
          onChangeProperty={onChangeProperty}
          placeholder={T.admin.modbussignal.connectiondefault}
        />
      </div>
    </>
  );
};

interface SignalPropertiesSectionProps {
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
}

export const SignalPropertiesSection = ({
  modbus,
  onChangeProperty
}: SignalPropertiesSectionProps) => {
  const isCoilOrHoldingRegister =
    modbus.type === SignalModbusType.Coil ||
    modbus.type === SignalModbusType.HoldingRegister;

  return (
    <div className={styles.gridFlow}>
      <SectionHeader title={T.admin.modbussignal.signalproperties} />
      <ModbusSelect
        name="type"
        title={T.admin.modbussignal.signaltype}
        values={ModbusTypeTranslations}
        modbus={modbus}
        onChangeProperty={onChangeProperty}
      />
      {isCoilOrHoldingRegister && (
        <ModbusSelect
          name="readwrite"
          title={T.admin.modbussignal.readwrite}
          values={ModbusReadwriteTypeTranslations}
          modbus={modbus}
          onChangeProperty={onChangeProperty}
        />
      )}
    </div>
  );
};

interface DataTypeSectionProps {
  modbus: SignalModbusConfigTemplateWithBitmaskResponseModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProperty(key: string, value: any): void;
}

export const DataTypeSection = ({
  modbus,
  onChangeProperty
}: DataTypeSectionProps) => {
  // TODO: Handle this nicely
  const isBitmask = modbus.datatype === ModbusDataTypeExpanded.BITMASK;

  const typeValue = () => {
    if (isBitmask || modbus.datatype === ModbusDataTypeExpanded.REAL) {
      return modbus.datatype;
    }

    let ret = '';
    if (!modbus.signed) {
      ret = 'u';
    }

    ret += 'int';
    ret += modbus.size;

    return ret;
  };

  return (
    <div className={styles.gridFlow}>
      <SectionHeader title={T.admin.modbussignal.datatype.title} />
      <ModbusSelect
        name="datatype"
        title={T.admin.modbussignal.datatype.title}
        value={typeValue}
        values={ModbusDataTypeTranslations}
        modbus={modbus}
        onChangeProperty={onChangeProperty}
      />
      {!isBitmask && (
        <Fragment>
          <ModbusSelect
            name="byteOrder"
            title={T.admin.modbussignal.byteorder.title}
            values={ModbusByteorderTranslations}
            modbus={modbus}
            onChangeProperty={onChangeProperty}
          />
          <ModbusNumberInput
            name="factor"
            title={T.admin.modbussignal.factor}
            disabled={modbus.bit != null && modbus.bit !== 0}
            modbus={modbus}
            onChangeProperty={onChangeProperty}
          />
          <ModbusNumberInput
            name="step"
            title={T.admin.modbussignal.step}
            disabled={modbus.bit != null && modbus.bit !== 0}
            modbus={modbus}
            onChangeProperty={onChangeProperty}
          />
        </Fragment>
      )}
      {isBitmask && (
        <ModbusNumberInput
          name="bit"
          title={T.admin.modbussignal.bit}
          modbus={modbus}
          onChangeProperty={onChangeProperty}
        />
      )}
    </div>
  );
};

interface ConnectionAreaProps {
  connection?: ConnectionResponseModel;
  modbusMode?: string;
}

export const ConnectionArea = ({
  connection,
  modbusMode
}: ConnectionAreaProps) => {
  // Get the name from the EM that owns the connection
  const { node: emEquipment } = useNode(connection?.id);

  return (
    <div>
      <div className={styles.gridFlow}>
        <SectionHeader title={T.admin.modbussignal.connection} />
        <SectionItem title={T.admin.modbussignal.connection}>
          {emEquipment.name ?? connection.name}
        </SectionItem>
        <SectionItem title={T.admin.modbussignal.mode}>
          {modbusModeToConfigType(modbusMode)}
        </SectionItem>
      </div>
    </div>
  );
};
