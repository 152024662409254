import React, { useState, useCallback, useContext } from 'react';
import _ from 'lodash';

import Spinner from 'ecto-common/lib/Spinner/Spinner';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';

import EditEnergyManagerSettings from 'js/components/EditBuildingData/EditEnergyManagerSettings';
import ModbusConnectionDialog from 'js/components/EditBuildingData/ModbusConnectionDialog';
import { handleModbusConnectionChange } from 'js/components/ModbusLayout/ModbusEditUtils';
import styles from 'js/components/ManageEquipment/EditEnergyManager/EditEnergyManager.module.css';
import APIGen, {
  AddOrUpdateConnectionRequestModel,
  ConnectionResponseModel,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface EditEnergyManagerProps {
  equipment?: NodeV2ResponseModel;
}

const EditEnergyManager = ({ equipment }: EditEnergyManagerProps) => {
  const [connectionData, setConnectionData] =
    useState<ConnectionResponseModel>(null);
  const [showingEditConnection, showEditConnection, hideEditConnection] =
    useDialogState('edit-em-settings');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const connectionsQuery = APIGen.AdminDevices.getConnections.useQuery(
    {
      ConnectionIds: [equipment?.nodeId]
    },
    {
      enabled: equipment?.nodeId != null
    }
  );
  const originalConnectionData = connectionsQuery.data?.[0];

  if (originalConnectionData != null && connectionData == null) {
    setConnectionData(connectionsQuery.data[0]);
    setHasUnsavedChanges(false);
  }

  const onClose = useCallback(() => {
    setConnectionData(originalConnectionData);
    setHasUnsavedChanges(false);
    hideEditConnection();
  }, [hideEditConnection, setConnectionData, originalConnectionData]);

  const onChangeConnectionProperty = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, value: any) => {
      const _connectionData = Object.assign({}, { ...connectionData });
      _connectionData.connectionModbusConfig = Object.assign(
        {},
        connectionData.connectionModbusConfig
      );

      handleModbusConnectionChange(
        _connectionData.connectionModbusConfig,
        key,
        value
      );
      setHasUnsavedChanges(!_.isEqual(_connectionData, originalConnectionData));

      setConnectionData(_connectionData);
    },
    [connectionData, originalConnectionData]
  );

  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const setConnectionMutation =
    APIGen.AdminDevices.addOrUpdateConnections.useMutation({
      onSuccess: () => {
        setHasUnsavedChanges(false);
        toastStore.addSuccessToast(T.admin.requests.updatedeviceconfig.success);
        queryClient.invalidateQueries({
          queryKey: APIGen.AdminDevices.getConnections.path(contextSettings)
        });
        hideEditConnection();
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.requests.updatedeviceconfig.failure);
      }
    });

  const isLoading = connectionsQuery.isLoading;

  const saveButtonEnabled = hasUnsavedChanges;

  const onSave = useCallback(() => {
    // Cast due to non-required members in ConnectionResponse
    setConnectionMutation.mutate([
      connectionData as AddOrUpdateConnectionRequestModel
    ]);
  }, [connectionData, setConnectionMutation]);

  return (
    <div>
      {isLoading && <Spinner />}

      {!isLoading && connectionData != null && (
        <div>
          <div className={styles.sectionSpacer} />
          <LoadingContainer isLoading={isLoading}>
            <EditEnergyManagerSettings
              connectionData={connectionData}
              onEditConnection={showEditConnection}
            />

            <ModbusConnectionDialog
              selectedConnection={connectionData.connectionModbusConfig}
              onChangeProperty={onChangeConnectionProperty}
              onModalClose={onClose}
              showSaveButton
              disableClose={setConnectionMutation.isPending}
              saveButtonEnabled={saveButtonEnabled}
              onSave={onSave}
              isOpen={showingEditConnection}
              isLoading={setConnectionMutation.isPending}
            />
          </LoadingContainer>
        </div>
      )}
    </div>
  );
};

export default EditEnergyManager;
