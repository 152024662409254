import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';

import Layout from 'ecto-common/lib/Layout/Layout';
import ContentArea from 'ecto-common/lib/Layout/ContentArea/ContentArea';
import ToastContainer from 'ecto-common/lib/Toast/ToastContainer';
import BaseContainer from 'ecto-common/lib/BaseContainer/BaseContainer';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import {
  REQ_STATE_ERROR,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS,
  RequestStatusRawProp
} from 'ecto-common/lib/utils/requestStatus';
import T from 'ecto-common/lib/lang/Language';
import { AuthenticationErrorComponent } from 'ecto-common/lib/AuthenticationWrapper/AuthenticationWrapper';

import { setEquipmentGroupTemplates } from 'js/actions/getEquipmentGroupTemplates';

import { setEnums } from 'ecto-common/lib/actions/getEnums';

import styles from './AdminContainer.module.css';
import useAuthentication from 'ecto-common/lib/hooks/useAuthentication';
import { setSignalTypes } from 'ecto-common/lib/actions/setSignalTypes';
import { setSignalTypeFolders } from 'ecto-common/lib/actions/setSignalTypeFolders';
import LoadingScreenWithMenu from 'ecto-common/lib/LoadingScreen/LoadingScreenWithMenu';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import { setNodeTags } from 'ecto-common/lib/actions/getNodeTags';
import { setSignalTemplates } from 'js/actions/getSignalTemplates';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants/index';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AuthError } from '@azure/msal-browser';
import { useInitialUserSettings } from 'ecto-common/lib/Application/useInitialUserSettings';
import { Outlet } from 'react-router-dom';
import APIGen from 'ecto-common/lib/API/APIGen';
import { useCommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import { useQuery } from '@tanstack/react-query';
import { SET_ROOT_LEVEL_NODES } from 'ecto-common/lib/actions/actionTypes';

const getSignalTemplatesPromise = (
  contextSettings: ApiContextSettings,
  signal: AbortSignal
) => {
  return Promise.all([
    APIGen.AdminAlarms.getAllAlarmSignalGroupTemplates.promise(
      contextSettings,
      signal
    ),
    APIGen.AdminEquipments.getEquipmentSignalProviderTemplates.promise(
      contextSettings,
      {},
      signal
    )
  ] as const);
};

export function getNodesPromise(
  contextSettings: ApiContextSettings,
  signal: AbortSignal
) {
  return Promise.all([
    APIGen.AdminNodes.getNodes.promise(contextSettings, {}, signal),
    APIGen.Nodes.getGrids.promise(contextSettings, signal)
  ] as const);
}

const CoreResourceWrapper = React.memo(() => {
  const apiEnvironment = getApiEnvironment();

  const { userId } = useContext(UserContext);
  const { isAuthenticated, instance, currentAccount, isLoggingOut } =
    useAuthentication(apiEnvironment.scopes.gateway);
  const { contextSettings, tenantId } = useContext(TenantContext);

  const queryOptions = {
    enabled: userId != null && tenantId != null,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  };

  const dispatch = useCommonDispatch();

  // This is really ugly, but needed to work around syncing between react query and redux.
  // If we only rely on loading state on the query return object, then the UI will render
  // a frame while the data has been loaded, but not synced to redux yet.
  //
  // And if we add the dispatch as part of the promise chain in the query, then it will
  // not run if the query is cached.
  const [loadingState, setLoadingState] = useState({
    nodeTags: true,
    equipmentTypes: true,
    enums: true,
    signalTypeFolders: true,
    signalTypes: true,
    rootLevelNodes: true
  });

  const enumsQuery =
    APIGen.Enums.getEnumsAndFixedConfigurations.useQuery(queryOptions);

  useEffect(() => {
    if (enumsQuery.data) {
      dispatch(setEnums(enumsQuery.data));
      setLoadingState((prev) => ({ ...prev, enums: false }));
    }
  }, [enumsQuery.data, dispatch]);

  const signalTemplatesQuery = useQuery({
    queryKey: ['allSignalTemplates', tenantId],
    queryFn: ({ signal }) => {
      return getSignalTemplatesPromise(contextSettings, signal);
    },
    ...queryOptions
  });

  useEffect(() => {
    if (signalTemplatesQuery.data) {
      dispatch(setSignalTemplates(signalTemplatesQuery.data));
    }
  }, [signalTemplatesQuery.data, dispatch]);

  const nodeTagsQuery = APIGen.AdminNodes.getNodeTags.useQuery(queryOptions);

  useEffect(() => {
    if (nodeTagsQuery.data) {
      dispatch(setNodeTags(nodeTagsQuery.data));
      setLoadingState((prev) => ({ ...prev, nodeTags: false }));
    }
  }, [nodeTagsQuery.data, dispatch]);

  // Just fire off this query here, but don't track loading state since it's not necessary to render.
  const equipmentGroupsQuery =
    APIGen.AdminBuildings.getBuildingTemplates.useQuery(queryOptions);

  useEffect(() => {
    if (equipmentGroupsQuery.data) {
      dispatch(setEquipmentGroupTemplates(equipmentGroupsQuery.data));
    }
  }, [equipmentGroupsQuery.data, dispatch]);

  const equipmentTypesQuery =
    APIGen.AdminEquipments.getEquipmentTypes.useQuery(queryOptions);

  useEffect(() => {
    if (equipmentTypesQuery.data) {
      dispatch(setEquipmentTypes(equipmentTypesQuery.data));
      setLoadingState((prev) => ({ ...prev, equipmentTypes: false }));
    }
  }, [equipmentTypesQuery.data, dispatch]);

  const rootLevelNodesQuery = APIGen.NodesV2.getNodeChildren.useQuery(
    {
      nodeIds: []
    },
    queryOptions
  );

  useEffect(() => {
    if (rootLevelNodesQuery.data) {
      dispatch({
        type: SET_ROOT_LEVEL_NODES,
        payload: {
          nodes: rootLevelNodesQuery.data
        }
      });
      setLoadingState((prev) => ({ ...prev, rootLevelNodes: false }));
    }
  }, [dispatch, rootLevelNodesQuery.data]);

  const signalTypesQuery =
    APIGen.AdminSignalTypes.getAllSignalTypes.useQuery(queryOptions);

  useEffect(() => {
    if (signalTypesQuery.data) {
      dispatch(setSignalTypes(signalTypesQuery.data));
      setLoadingState((prev) => ({ ...prev, signalTypes: false }));
    }
  }, [signalTypesQuery.data, dispatch]);

  const signalTypeFoldersQuery =
    APIGen.AdminSignalTypeFolders.getAllSignalTypeFolders.useQuery(
      queryOptions
    );

  useEffect(() => {
    if (signalTypeFoldersQuery.data) {
      dispatch(setSignalTypeFolders(signalTypeFoldersQuery.data));
      setLoadingState((prev) => ({ ...prev, signalTypeFolders: false }));
    }
  }, [signalTypeFoldersQuery.data, dispatch]);

  const hasError =
    enumsQuery.isError ||
    signalTemplatesQuery.isError ||
    nodeTagsQuery.isError ||
    equipmentGroupsQuery.isError ||
    equipmentTypesQuery.isError ||
    signalTypesQuery.isError ||
    signalTypeFoldersQuery.isError;

  useEffect(() => {
    if (hasError && isAuthenticated) {
      toastStore.addErrorToast(T.common.baserequesterror);
    }
  }, [hasError, isAuthenticated]);

  const isLoading = Object.values(loadingState).some((v) => v);

  if (isLoading) {
    return <LoadingScreenWithMenu isLoading />;
  } else if (currentAccount && !isLoggingOut) {
    return (
      <BaseContainer
        msalConfiguration={instance}
        currentAccount={currentAccount}
      >
        <Outlet />
      </BaseContainer>
    );
  }

  return null;
});

const Container = React.memo(() => {
  const apiEnvironment = getApiEnvironment();
  const {
    isLoading: authenticationIsLoading,
    errorMessage,
    instance,
    currentAccount,
    isLoggingOut
  } = useAuthentication(apiEnvironment.scopes.gateway);

  const userSettingsQuery = useInitialUserSettings(
    !!currentAccount && !errorMessage
  );

  const { isLoadingTenants, tenantsFailedToLoad, tenantResources } =
    useContext(TenantContext);
  const isLoading =
    authenticationIsLoading || isLoadingTenants || userSettingsQuery.isLoading;
  let _errorMessage = errorMessage;

  if (tenantsFailedToLoad) {
    _errorMessage = AuthError.createUnexpectedError(
      T.tenants.error.failedtoload
    );
  }

  let content: React.ReactNode = null;
  if (isLoading) {
    content = <LoadingScreenWithMenu isLoading />;
  } else if (hasAccessToResource(ResourceType.CORE, tenantResources)) {
    content = <CoreResourceWrapper />;
  } else if (currentAccount && !isLoggingOut) {
    content = (
      <BaseContainer
        msalConfiguration={instance}
        currentAccount={currentAccount}
      >
        <Outlet />
      </BaseContainer>
    );
  }

  return (
    <>
      {!_errorMessage ? (
        <div className={styles.baseContainer}>{content}</div>
      ) : (
        <AuthenticationErrorComponent error={_errorMessage} />
      )}
    </>
  );
});

const MainContentArea = React.memo(() => {
  const { tenantId } = useContext(TenantContext);

  return (
    <ContentArea>
      <Container key={tenantId} />
      <ToastContainer />
    </ContentArea>
  );
});

const useReqStateToast = () => {
  const reqState = useAdminSelector((state) => state.reqState);
  const prevReqState = useRef(_.cloneDeep(reqState));

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nextReqState: any = reqState;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _prevReqState: any = prevReqState.current;

    Object.keys(nextReqState).forEach((key) => {
      if (
        nextReqState[key].statusText &&
        nextReqState[key].statusText !== _prevReqState[key].statusText
      ) {
        if (nextReqState[key].state === REQ_STATE_SUCCESS) {
          toastStore.addSuccessToast(nextReqState[key].statusText);
        } else if (nextReqState[key].state === REQ_STATE_ERROR) {
          toastStore.addErrorToast(nextReqState[key].statusText);
        }
      }
    });
    prevReqState.current = _.cloneDeep(reqState);
  }, [reqState]);

  return useMemo(() => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.values(reqState as unknown as RequestStatusRawProp<any>[]).filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (request: RequestStatusRawProp<any>) =>
          request.state === REQ_STATE_PENDING && request.blocking
      ).length !== 0
    );
  }, [reqState]);
};

const AdminContainer = () => {
  const isBlocking = useReqStateToast();
  return <Layout disabled={isBlocking} contentArea={<MainContentArea />} />;
};

export default React.memo(AdminContainer);
