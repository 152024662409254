import React, { useCallback, useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';

import Notice from 'ecto-common/lib/Notice/Notice';

import {
  EnergyManagerSubpage,
  EquipmentSubpage
} from 'js/utils/LocationEndpoints';
import EditEquipmentFields from 'js/components/ManageEquipment/EditEquipment/EditEquipmentFields';
import EditEquipmentSignals from 'js/components/ManageEquipment/EditEquipment/EditEquipmentSignals';
import ProvisionDevice from 'js/components/ManageEquipment/ProvisionDevice/ProvisionDevice';
import EditEnergyManager from 'js/components/ManageEquipment/EditEnergyManager/EditEnergyManager';
import DeployEnergyManager from 'js/components/ManageEquipment/DeployEnergyManager/DeployEnergyManager';
import EditEquipmentTools from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import { getEquipmentPageUrl } from 'js/utils/linkUtil';
import styles from 'js/components/Equipments/Equipments.module.css';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { nodeIsEnergyManager } from 'ecto-common/lib/hooks/useCurrentNode';

type SegmentItem = {
  title: string;
  icon: React.ReactNode;
  page: string;
  pageTag: (
    equipment: NodeV2ResponseModel,
    energyManagerEquipmentId: string
  ) => React.ReactNode;
};

interface EquipmentsContentProps {
  selectedEquipment?: NodeV2ResponseModel;
  equipments: NodeV2ResponseModel[];
}

const EquipmentsContent = ({
  selectedEquipment,
  equipments
}: EquipmentsContentProps) => {
  const em = _.find(equipments, (equipment) => nodeIsEnergyManager(equipment));

  const energyManagerEquipmentId = em?.nodeId;
  const { tenantId } = useContext(TenantContext);

  const params = useParams<NodeParams>();
  const navigate = useNavigate();

  const changePage = useCallback(
    (newItemId: string, newSubpage: string) => {
      const { nodeId, itemId, subPage } = params;

      if (subPage === newSubpage && newItemId === itemId) {
        return;
      }

      navigate(getEquipmentPageUrl(tenantId, nodeId, newItemId, newSubpage));
    },
    [params, navigate, tenantId]
  );

  const changeSubpage = useCallback(
    (newSubpage: string) => {
      const { itemId } = params;

      changePage(itemId, newSubpage);
    },
    [changePage, params]
  );

  const segmentItems = useMemo(() => {
    let items: SegmentItem[] = [
      {
        title: T.admin.equipment.section.details,
        icon: <Icons.Equipment />,
        page: EquipmentSubpage.DETAILS,
        pageTag: (equipment) => <EditEquipmentFields equipment={equipment} />
      },
      {
        title: T.admin.equipment.section.signals,
        icon: <Icons.Signal />,
        page: EquipmentSubpage.SIGNALS,
        pageTag: (equipment) => <EditEquipmentSignals equipment={equipment} />
      }
    ];

    if (nodeIsEnergyManager(selectedEquipment)) {
      items = [
        ...items,
        ...[
          {
            title: T.admin.equipment.section.iotdevice,
            icon: <Icons.Settings />,
            page: EnergyManagerSubpage.IOTDEVICE,
            pageTag: (equipment: NodeV2ResponseModel) => (
              <ProvisionDevice equipment={equipment} />
            )
          },
          {
            title: T.admin.equipment.section.connections,
            icon: <Icons.EnergyManager />,
            page: EnergyManagerSubpage.CONNECTIONS,
            pageTag: (equipment: NodeV2ResponseModel) => (
              <EditEnergyManager equipment={equipment} />
            )
          },
          {
            title: T.admin.equipment.section.deployment,
            icon: <Icons.Connect />,
            page: EnergyManagerSubpage.DEPLOYMENT,
            pageTag: (equipment: NodeV2ResponseModel) => (
              <DeployEnergyManager equipment={equipment} />
            )
          }
        ]
      ];
    } else {
      items.push({
        title: T.admin.equipment.section.tools,
        icon: <Icons.Tool />,
        page: EquipmentSubpage.TOOLS,
        pageTag: (equipment, energyManagerEquipmentIdArg) => (
          <EditEquipmentTools
            equipment={equipment}
            energyManagerEquipmentId={energyManagerEquipmentIdArg}
          />
        )
      });
    }

    return items;
  }, [selectedEquipment]);

  const equipmentComponent = useMemo(
    () =>
      segmentItems
        .find((item) => item.page === params?.subPage)
        .pageTag(selectedEquipment, energyManagerEquipmentId),
    [energyManagerEquipmentId, params?.subPage, segmentItems, selectedEquipment]
  );

  return (
    <>
      {!selectedEquipment ? (
        <div>
          <Notice showHeader>{T.admin.equipment.error.nodevice}</Notice>
        </div>
      ) : (
        <>
          <div className={styles.subpageSelector}>
            <SegmentControl>
              {segmentItems.map(({ title, icon, page }) => (
                <SegmentControlItem
                  key={page}
                  onClick={() => changeSubpage(page)}
                  active={page === params?.subPage}
                  compact
                >
                  {icon}
                  {title}
                </SegmentControlItem>
              ))}
            </SegmentControl>
          </div>

          {equipmentComponent}
        </>
      )}
    </>
  );
};

export default EquipmentsContent;
