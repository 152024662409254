import React from 'react';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { useParams } from 'react-router-dom';
import EditLocation from 'js/components/EditLocation/EditLocation';
import Equipments from 'js/components/Equipments/Equipments';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { useNodeChildren } from 'ecto-common/lib/hooks/useCurrentNode';
import _ from 'lodash';

type EditLocationOrEquipmentProps = {
  onTitleChanged: (title: string[]) => void;
  selectedLocation: NodeV2ResponseModel;
};

const EditLocationOrEquipment = ({
  onTitleChanged,
  selectedLocation
}: EditLocationOrEquipmentProps) => {
  const params = useParams<NodeParams>();

  const nodeChildren = useNodeChildren(_.compact([selectedLocation?.nodeId]));

  if (params.itemId != null) {
    return (
      <Equipments
        onTitleChanged={onTitleChanged}
        selectedLocation={selectedLocation}
        equipments={nodeChildren.nodeChildren}
      />
    );
  }

  return (
    <EditLocation
      onTitleChanged={onTitleChanged}
      selectedLocation={selectedLocation}
    />
  );
};

export default React.memo(EditLocationOrEquipment);
