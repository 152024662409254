import { COMFORT_DOMAIN_MODELS } from 'js/components/ManageEquipment/EditEquipment/Comfort/comfortModelFormUtils';
import _ from 'lodash';
import { ComfortEquipmentWithToolData } from '../ManageComfortTools';
import {
  AddOrUpdateComfortToolSetResponseModel,
  CreateComfortHeatingByTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';

export function mostCommonValue<CollectionType>(
  collection: CollectionType[],
  key: string
) {
  const values = _.map(collection, key);

  const filteredValues = _.reject(values, _.isNil);

  const groupIndex = _.head(
    _(filteredValues).countBy().entries().maxBy(_.last)
  );

  // Have to retrieve value using key to group instead of just returning key as
  // key is always string.
  if (groupIndex != null) {
    const groups = _.groupBy(filteredValues);
    return _.head(groups[groupIndex]);
  }

  return null;
}

export const createMergedComfortHeatingParameters = (
  parameters: CreateComfortHeatingByTemplateResponseModel[]
): AddOrUpdateComfortToolSetResponseModel => {
  const heatingParameters = _.map(
    parameters,
    'addOrUpdateComfortHeatingProvider.addOrUpdateComfortToolSet'
  );

  // These do not include all properties, they are a partial subset of all available
  // Type is AddOrUpdateComfortToolSetResponseModel
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let propDefinitions: Record<string, any> = {
    pidControl: COMFORT_DOMAIN_MODELS.PidControl,
    highPassControl: COMFORT_DOMAIN_MODELS.Highpass,
    integralGainSchedulingOutTemp: COMFORT_DOMAIN_MODELS.IntegralGainShape,
    integralGainSchedulingTimeOfDayWeekday:
      COMFORT_DOMAIN_MODELS.IntegralGainShape,
    integralGainSchedulingTimeOfDayWeekend:
      COMFORT_DOMAIN_MODELS.IntegralGainShape,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    integralGainSchedulingWindFactor: null as any
  };

  if (mostCommonValue(heatingParameters, 'integralGainSchedulingWindFactor')) {
    propDefinitions = {
      ...propDefinitions,
      integralGainSchedulingWindFactor:
        COMFORT_DOMAIN_MODELS.IntegralGainWindspeed
    };
  }

  const mergedHeatingParameters: AddOrUpdateComfortToolSetResponseModel = {
    id: undefined,
    alarmConfigurations: null
  };

  for (const objectKey of _.keys(propDefinitions)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const algorithmSettings: Record<string, any> = {};

    if (propDefinitions[objectKey] == null) {
      continue;
    }

    for (const propKey of _.keys(propDefinitions[objectKey])) {
      algorithmSettings[propKey] = mostCommonValue(
        heatingParameters,
        objectKey + '.' + propKey
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (mergedHeatingParameters as any)[objectKey] = algorithmSettings;
  }

  mergedHeatingParameters.alarmConfigurations = _.flatMap(
    heatingParameters,
    'alarmConfigurations'
  );

  return mergedHeatingParameters;
};

export const getBatchEditedComfortParametersToSave = (
  _equipments: ComfortEquipmentWithToolData[],
  editParameters: CreateComfortHeatingByTemplateResponseModel[],
  editedComfortHeatingParameters: AddOrUpdateComfortToolSetResponseModel
) => {
  const parametersToSave = _.cloneDeep(editParameters);

  for (const parameter of parametersToSave) {
    const sharedProperties = _.without(
      _.keys(editedComfortHeatingParameters),
      'alarmConfigurations',
      'id'
    );

    const toolset =
      parameter.addOrUpdateComfortHeatingProvider.addOrUpdateComfortToolSet;

    for (const sharedProperty of sharedProperties) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (toolset as any)[sharedProperty] = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(toolset as any)[sharedProperty],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(editedComfortHeatingParameters as any)[sharedProperty]
      };
    }

    for (const alarmConfig of toolset.alarmConfigurations) {
      const editedConfig = _.find(
        editedComfortHeatingParameters.alarmConfigurations,
        { id: alarmConfig.id }
      );
      if (editedConfig == null) {
        console.error('Failed to find edited alarm configuration');
        continue;
      }

      // TODO: It's slightly ugly that we have to specify which fields to copy here as its derived from the prop type for the other settings
      alarmConfig.timeDelay = editedConfig.timeDelay;
      for (const signalInputConfiguration of alarmConfig.inputSignalConfigurations) {
        const editedInputConfiguration = _.find(
          editedConfig.inputSignalConfigurations,
          { id: signalInputConfiguration.id }
        );

        if (editedInputConfiguration == null) {
          console.error(
            'Failed to find edited alarm signal input configuration'
          );
          continue;
        }

        signalInputConfiguration.minDiffValue =
          editedInputConfiguration.minDiffValue;
        signalInputConfiguration.maxDiffValue =
          editedInputConfiguration.maxDiffValue;
        signalInputConfiguration.minValue = editedInputConfiguration.minValue;
        signalInputConfiguration.maxValue = editedInputConfiguration.maxValue;
      }
    }
  }

  return parametersToSave;
};

export type BatchEditEquipmentParameters = {
  outTemperatureSignalId: string;
  windSpeedSignalId: string;
  equipmentSettings: {
    displayName: string;
    meanTemperatureSignalId: string;
    equipmentId: string;
    providerId: string;
  }[];
};

export const createInputForEquipments = (
  equipments: ComfortEquipmentWithToolData[]
): BatchEditEquipmentParameters => {
  return {
    outTemperatureSignalId: mostCommonValue(
      equipments,
      'tool.outTemperatureSignalId'
    ),
    windSpeedSignalId: mostCommonValue(equipments, 'tool.windSpeedSignalId'),
    equipmentSettings: _.map(equipments, (equipment) => ({
      displayName:
        equipment.tool != null
          ? equipment.tool.displayName
          : 'Comfort ' + equipment.name,
      meanTemperatureSignalId: equipment.tool?.meanTemperatureSignalId,
      equipmentId: equipment.nodeId,
      providerId: equipment.tool?.providerId
    }))
  };
};
