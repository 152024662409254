import React, { useMemo, useCallback, useContext } from 'react';

import T from 'ecto-common/lib/lang/Language';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import zindex from 'ecto-common/lib/styles/variables/zindex';
import Icons from 'ecto-common/lib/Icons/Icons';
import { PowerControlAlgorithmType } from 'ecto-common/lib/constants';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import HelpPaths from 'ecto-common/help/tocKeys';

import { EditEquipmentToolsActions } from 'js/modules/editEquipmentTools/editEquipmentTools';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import {
  PowerControlProviderResponseModel,
  PowerControlType
} from 'ecto-common/lib/API/APIGen';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useQueryClient } from '@tanstack/react-query';

const PowerControlTypes = enumValues(PowerControlType).filter(
  (x) => x !== PowerControlType.Unknown
);

const AlgorithmTypes = enumValues(PowerControlAlgorithmType);

const algorithmOptions: GenericSelectOption<string>[] = AlgorithmTypes.map(
  (name) => {
    return {
      label:
        T.admin.equipment.algorithmtype[
          name.toLowerCase() as keyof typeof T.admin.equipment.algorithmtype
        ],
      value: name
    };
  }
);

const powerControlOptions: GenericSelectOption<string>[] =
  PowerControlTypes.map((name) => {
    return {
      label:
        T.admin.equipment.powercontroltype[
          name.toLowerCase() as keyof typeof T.admin.equipment.powercontroltype
        ],
      value: name
    };
  });

interface EditPowerControlDialogProps {
  isOpen?: boolean;
  powerControl?: PowerControlProviderResponseModel;
}

const EditPowerControlDialog = ({
  isOpen,
  powerControl
}: EditPowerControlDialogProps) => {
  const dispatch = useAdminDispatch();
  const nodeId = useAdminSelector((state) => state.general.nodeId);
  const { contextSettings } = useContext(TenantContext);
  const selectedAlgorithmOption = useMemo(
    () => algorithmOptions.find((x) => x.value === powerControl?.algorithmType),
    [powerControl?.algorithmType]
  );

  const selectedPowerControlOption = useMemo(
    () =>
      powerControlOptions.find(
        (x) => x.value === powerControl?.powerControlType
      ),
    [powerControl?.powerControlType]
  );

  const title = useMemo(() => {
    if (powerControl) {
      if (powerControl.signalProviderId) {
        return T.admin.equipment.editpowercontrol;
      }

      return T.admin.equipment.addnewpowercontrol;
    }

    return '';
  }, [powerControl]);

  const onModalClose = useCallback(() => {
    dispatch(EditEquipmentToolsActions.setEditablePowerControl(null));
  }, [dispatch]);

  const queryClient = useQueryClient();

  const onConfirmClick = useCallback(() => {
    dispatch(EditEquipmentToolsActions.setEditablePowerControl(null));
    dispatch(
      EditEquipmentToolsActions.updatePowerControl(
        contextSettings,
        powerControl,
        nodeId,
        queryClient
      )
    );
  }, [contextSettings, dispatch, nodeId, powerControl, queryClient]);

  const onPowerControlOptionChange = useCallback(
    (val: GenericSelectOption<string>) => {
      dispatch(
        EditEquipmentToolsActions.setEditablePowerControl({
          ...powerControl,
          powerControlType: val.value
        })
      );
    },
    [dispatch, powerControl]
  );

  const onAlgorithmOptionChange = useCallback(
    (val: GenericSelectOption<string>) => {
      dispatch(
        EditEquipmentToolsActions.setEditablePowerControl({
          ...powerControl,
          algorithmType: val.value
        })
      );
    },
    [dispatch, powerControl]
  );

  return (
    <ActionModal
      onModalClose={onModalClose}
      isOpen={isOpen}
      title={title}
      headerIcon={Icons.Edit}
      onConfirmClick={onConfirmClick}
      helpPath={HelpPaths.docs.admin.manage.equipment.tools.power_control}
    >
      {powerControl && (
        <KeyValueLine>
          <KeyValueGeneric keyText={T.admin.equipment.powercontroltype.title}>
            <Select<GenericSelectOption<string>, false>
              menuPortalTarget={document.body}
              isSearchable={false}
              options={powerControlOptions}
              onChange={onPowerControlOptionChange}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: zindex.modalOverlayZIndex
                })
              }}
              value={selectedPowerControlOption}
            />
          </KeyValueGeneric>

          <KeyValueGeneric keyText={T.admin.equipment.algorithmtype.title}>
            <Select<GenericSelectOption<string>, false>
              menuPortalTarget={document.body}
              isSearchable={false}
              options={algorithmOptions}
              onChange={onAlgorithmOptionChange}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: zindex.modalOverlayZIndex
                })
              }}
              value={selectedAlgorithmOption}
            />
          </KeyValueGeneric>
        </KeyValueLine>
      )}

      {!powerControl && <span />}
    </ActionModal>
  );
};

export default EditPowerControlDialog;
