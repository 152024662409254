import React, { useCallback, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import Button from 'ecto-common/lib/Button/Button';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import T from 'ecto-common/lib/lang/Language';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';

import useDialogState, {
  useSimpleDialogState
} from 'ecto-common/lib/hooks/useDialogState';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import SelectProcessMapDialog from 'ecto-common/lib/ProcessMaps/SelectProcessMapDialog';
import { getEquipmentPageUrl } from 'js/utils/linkUtil';
import _ from 'lodash';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import APIGen, { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { useQueryClient } from '@tanstack/react-query';
import { usePromptMessage } from 'ecto-common/lib/hooks/useBlockerListener';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import PresentationAPIGen from 'ecto-common/lib/API/PresentationAPIGen';
import { usePresentationItemQuery } from 'ecto-common/lib/utils/presentationLibrary';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';
import Flex, { FlexItem, JustifyContent } from 'ecto-common/lib/Layout/Flex';
import LayoutDirection from 'ecto-common/lib/types/LayoutDirection';
import dimensions from 'ecto-common/lib/styles/dimensions';
import {
  resetCacheForNode,
  updateNodeTreeIncrementallyFromDelete
} from 'js/modules/provisioningCommon/provisioningCommon';
import { useNodeChildren } from 'ecto-common/lib/hooks/useCurrentNode';
import { NodeTraitIds } from 'ecto-common/lib/utils/constants';

interface EditEquipmentFieldsProps {
  equipment?: NodeV2ResponseModel;
}

const processMapsColumns = [
  {
    label: T.common.name,
    dataKey: 'name',
    idKey: 'id',
    dataFormatter: (value: string) => {
      return (
        <>
          <Icons.File /> {value}{' '}
        </>
      );
    }
  },
  {
    label: T.common.description,
    dataKey: 'description'
  }
];

const EditEquipmentFields = ({ equipment }: EditEquipmentFieldsProps) => {
  const { nodeId, parentId } = equipment;
  const [name, setName] = useState(equipment?.name ?? '');
  const [description, setDescription] = useState(equipment?.description ?? '');

  const hasUnsavedChanges =
    name !== (equipment?.name ?? '') ||
    description !== (equipment?.description ?? '');

  const [showModal, onShowModal, onHideModal] = useSimpleDialogState();

  const [showProcessMapDialog, onShowProcessMapDialog, onHideProcessMapDialog] =
    useDialogState('select-process-map-dialog');
  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setName(event.target.value),
    []
  );
  const onDescriptionChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => setDescription(event.target.value), []);
  const navigate = useNavigate();

  const { tenantId } = useContext(TenantContext);

  const siblingsQuery = useNodeChildren(_.compact([parentId]));
  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const deleteMutation = APIGen.AdminNodes.deleteNode.useMutation({
    onSuccess: (_unused, args) => {
      const siblingsWithoutNodes = _.filter(
        siblingsQuery.nodeChildren,
        (node) => node.nodeId !== args.nodeId
      );

      updateNodeTreeIncrementallyFromDelete(
        args.nodeId,
        parentId,
        queryClient,
        contextSettings
      );

      toastStore.addSuccessToast(T.admin.equipment.delete.success);

      navigate(
        getEquipmentPageUrl(
          tenantId,
          parentId,
          siblingsWithoutNodes[0]?.nodeId,
          'details'
        )
      );
      onHideModal();
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipment.delete.error);
    }
  });

  const saveMutation = APIGen.AdminEquipments.updateEquipments.useMutation({
    onSuccess: () => {
      resetCacheForNode(contextSettings, nodeId, parentId, queryClient);
    },
    onError: () => {
      toastStore.addErrorToast(T.common.unknownerror);
    }
  });

  // TODO: New domain model, this trait => equipment type mapping should be replaced
  const equipmentTypeId = equipment.nodeTraitIds.find(
    (x) => x !== NodeTraitIds.EQUIPMENT
  );

  const onSave = useCallback(() => {
    saveMutation.mutate([
      {
        equipmentId: nodeId,
        name,
        description,
        equipmentTypeId
      }
    ]);
  }, [saveMutation, nodeId, name, description, equipmentTypeId]);

  const hasValidName = !_.isEmpty(name);

  usePromptMessage(T.admin.form.unsavedstate, hasUnsavedChanges);
  const { query: relationQuery } = usePresentationItemQuery({
    queryHook: PresentationAPIGen.Nodes.getPictures.useQuery,
    nodeId: nodeId,
    equipmentTypeId
  });

  const onDelete = useCallback(() => {
    deleteMutation.mutate({ nodeId });
  }, [deleteMutation, nodeId]);

  return (
    <div>
      <Flex direction={LayoutDirection.VERTICAL}>
        <KeyValueLine shouldHaveBottomMargin={false}>
          <KeyValueInput
            onChange={onNameChange}
            keyText={T.admin.equipment.namelabel}
            value={name}
            hasError={!hasValidName}
          />

          <KeyValueInput
            onChange={onDescriptionChange}
            keyText={T.admin.equipment.descriptionlabel}
            value={description}
          />
        </KeyValueLine>
        <FlexItem>
          <KeyValueLabel>{T.admin.processmaps.title}</KeyValueLabel>
          <DataTable
            isLoading={
              relationQuery.isFetching && _.isEmpty(relationQuery.data?.items)
            }
            columns={processMapsColumns}
            data={relationQuery.data?.items}
            noDataText={T.common.noitemsselected}
            showNoticeHeaders={false}
            hasError={relationQuery.isError}
          />
        </FlexItem>
        <Flex justifyContent={JustifyContent.END} gap={dimensions.smallMargin}>
          <Button onClick={onShowProcessMapDialog}>
            <Icons.Settings />
            {T.admin.editlocation.editprocessmap}
          </Button>

          <DeleteButton
            onClick={onShowModal}
            disabled={siblingsQuery.isLoading}
          >
            {T.admin.equipment.deleteequipment}
          </DeleteButton>

          <LocalizedButtons.Save
            disabled={
              !hasUnsavedChanges || saveMutation.isPending || !hasValidName
            }
            onClick={onSave}
          />
        </Flex>
      </Flex>
      <ActionModal
        compact
        onModalClose={onHideModal}
        isOpen={showModal}
        isLoading={deleteMutation.isPending}
        headerIcon={Icons.Delete}
        title={T.admin.equipment.confirmdelete.title}
        actionText={T.common.delete}
        onConfirmClick={onDelete}
      >
        {T.admin.equipment.confirmdelete.message}
      </ActionModal>
      <SelectProcessMapDialog
        isOpen={showProcessMapDialog}
        onModalClose={onHideProcessMapDialog}
        nodeId={nodeId}
        equipmentTypeId={equipmentTypeId}
      />
    </div>
  );
};

export default EditEquipmentFields;
