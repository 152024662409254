import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useContext
} from 'react';
import classNames from 'classnames';

import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { NavLink } from 'react-router-dom';

import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';

import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';

import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import { mapReqStateToProp } from 'ecto-common/lib/utils/requestStatus';
import { getNodePage } from 'ecto-common/lib/utils/commonLinkUtil';
import { NodeTypes } from 'ecto-common/lib/utils/constants';

import ConfigureNodeFields from 'js/components/EditLocation/ConfigureNodeFields';
import { CreateNodeActions } from 'js/modules/createNodeForm/createNodeForm';
import { CreatingNodeState } from 'js/modules/createNodeForm/createNodeForm';

import { EquipmentTemplateFormActions } from 'js/modules/equipmentTemplateForm/equipmentTemplateForm';
import {
  translationsForNodeState,
  errorTranslationsForBuildingError,
  errorTranslationsForSiteError
} from 'js/components/EditLocation/translations';
import styles from 'js/components/EditLocation/CreateLocationDialog.module.css';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { useNavigate } from 'react-router-dom';
import {
  nodeIsBuilding,
  nodeIsSpace
} from 'ecto-common/lib/hooks/useCurrentNode';
import { useQueryClient } from '@tanstack/react-query';

const createToastBody = (
  tenantId: string,
  node: NodeV2ResponseModel,
  error: string,
  useLink: boolean,
  onModalClose: () => void
) => {
  const path = getNodePage(tenantId, node);
  const nodeName = useLink ? (
    <NavLink
      key={node.nodeId}
      onClick={() => {
        toastStore.clear();
        onModalClose();
      }}
      to={path}
    >
      {node.name}
    </NavLink>
  ) : (
    node.name
  );

  if (error) {
    if (nodeIsBuilding(node)) {
      return T.format(
        T.admin.createbuilding.success.witherrorsformat,
        nodeName,
        error
      );
    } else if (nodeIsSpace(node)) {
      return T.format(
        T.admin.createsite.success.witherrorsformat,
        nodeName,
        error
      );
    }
  }

  if (nodeIsBuilding(node)) {
    return T.format(T.admin.createbuilding.success.noerrorsformat, nodeName);
  } else if (nodeIsSpace(node)) {
    return T.format(T.admin.createsite.success.noerrorsformat, nodeName);
  }
};

interface CreateLocationDialogProps {
  isOpen: boolean;
  onModalClose: () => void;
  parentLocation: NodeV2ResponseModel;
}

const CreateLocationDialog = ({
  isOpen,
  onModalClose,
  parentLocation
}: CreateLocationDialogProps) => {
  const dispatch = useAdminDispatch();
  const navigate = useNavigate();
  const nodeType = useAdminSelector((state) => state.createNodeForm.nodeType);
  const name = useAdminSelector((state) => state.createNodeForm.name);
  const street = useAdminSelector((state) => state.createNodeForm.street);
  const addAnother = useAdminSelector(
    (state) => state.createNodeForm.addAnother
  );
  const createNodeState = useAdminSelector(
    (state) => state.createNodeForm.createNodeState
  );
  const createdNode = useAdminSelector(
    (state) => state.createNodeForm.createdNode
  );
  const createNodeError = useAdminSelector(
    (state) => state.createNodeForm.createNodeError
  );
  const initDeviceConfigReqState = useAdminSelector((state) =>
    mapReqStateToProp(state.equipmentTemplateForm.initDeviceConfigReqState)
  );

  const [toastHeader, setToastHeader] = useState<React.ReactNode>(null);
  const { tenantId, contextSettings } = useContext(TenantContext);

  const createToastHeader = useCallback(
    (node: NodeV2ResponseModel, error: string = undefined) => {
      return (
        <div className={styles.toastHeader}>
          {createToastBody(tenantId, node, error, true, onModalClose)}
        </div>
      );
    },
    [onModalClose, tenantId]
  );

  const showAddedLocationWithErrorToast = useCallback(
    (location: NodeV2ResponseModel, error: string) => {
      if (addAnother) {
        setToastHeader(createToastHeader(location, error));
      } else {
        const path = getNodePage(tenantId, location);
        toastStore.addErrorToast(
          createToastBody(tenantId, location, error, false, onModalClose)
        );
        navigate(path, { replace: true });
      }
    },
    [addAnother, onModalClose, createToastHeader, navigate, tenantId]
  );

  const showAddedLocationToast = useCallback(
    (location: NodeV2ResponseModel) => {
      if (addAnother) {
        setToastHeader(createToastHeader(location));
      } else {
        const path = getNodePage(tenantId, location);
        toastStore.addSuccessToast(
          createToastBody(tenantId, location, null, true, onModalClose)
        );
        navigate(path, { replace: true });
      }
    },
    [addAnother, onModalClose, createToastHeader, navigate, tenantId]
  );

  const localCreateNodeStateRef = useRef<CreatingNodeState | null>(undefined);

  useEffect(() => {
    setToastHeader(null);
  }, [isOpen]);

  useEffect(() => {
    if (createNodeState !== localCreateNodeStateRef.current) {
      if (createNodeState === CreatingNodeState.NODE_CREATED) {
        showAddedLocationToast(createdNode);

        if (!addAnother) {
          onModalClose();
        } else {
          dispatch(
            EquipmentTemplateFormActions.updateFormAfterBuildingAdded(
              contextSettings
            )
          );
        }

        dispatch(CreateNodeActions.clearCreateNodeState());
      } else if (createNodeState === CreatingNodeState.ERROR && isOpen) {
        const isBuilding = nodeType === NodeTypes.BUILDING;
        const isSite = nodeType === NodeTypes.SITE;
        let errorMessage;

        if (isBuilding) {
          errorMessage =
            errorTranslationsForBuildingError[createNodeError] ??
            T.admin.createbuilding.error.creating;
        }

        if (isSite) {
          errorMessage =
            errorTranslationsForSiteError[createNodeError] ??
            T.admin.createsite.error.creating;
        }

        if (createdNode) {
          showAddedLocationWithErrorToast(createdNode, errorMessage);

          dispatch(CreateNodeActions.setName(''));
        } else {
          toastStore.addErrorToast(errorMessage);
        }

        if (createdNode && !addAnother && onModalClose) {
          onModalClose();
        }
      }

      localCreateNodeStateRef.current = createNodeState;
    }
  }, [
    addAnother,
    createNodeError,
    createNodeState,
    createdNode,
    dispatch,
    isOpen,
    nodeType,
    onModalClose,
    showAddedLocationToast,
    showAddedLocationWithErrorToast,
    contextSettings
  ]);

  const queryClient = useQueryClient();
  const performAdd = useCallback(() => {
    setToastHeader(null);
    dispatch(
      CreateNodeActions.createNode(contextSettings, parentLocation, queryClient)
    );
  }, [dispatch, contextSettings, parentLocation, queryClient]);

  const loadingText = useMemo(
    () => translationsForNodeState[createNodeState],
    [createNodeState]
  );

  const onToggleAnother = useCallback(() => {
    dispatch(CreateNodeActions.setAddAnother(!addAnother));
  }, [addAnother, dispatch]);

  const isValid =
    !isNullOrWhitespace(name) &&
    (nodeType !== NodeTypes.BUILDING || !isNullOrWhitespace(street));
  const isLoading = loadingText != null || initDeviceConfigReqState.isLoading;
  const locationText =
    T.nodetypes[
      nodeType.toLowerCase() as keyof typeof T.nodetypes
    ].toLowerCase();

  return (
    <Modal
      className={styles.settingDialog}
      onModalClose={onModalClose}
      isOpen={isOpen}
      disableClose={isLoading}
    >
      <ModalHeader
        titleIcon={nodeType === NodeTypes.SITE ? Icons.Site : Icons.Building}
      >
        {T.format(T.admin.createlocation.titleformat, locationText)}
      </ModalHeader>

      <ModalBody
        loading={isLoading}
        loadingText={loadingText}
        className={classNames(styles.settingBody)}
      >
        {toastHeader}

        <ConfigureNodeFields
          nodeType={nodeType}
          parentLocation={parentLocation}
          isLoading={isLoading}
        />
      </ModalBody>

      <ModalFooter>
        <div className={styles.addAnotherContainer}>
          <Checkbox
            disabled={isLoading}
            id="addAnother"
            checked={addAnother}
            onChange={onToggleAnother}
          />

          <label
            className={classNames(
              styles.addAnother,
              isLoading && styles.disabled
            )}
            htmlFor={isLoading ? null : 'addAnother'}
            onClick={isLoading ? null : onToggleAnother}
          >
            {T.format(T.admin.createlocation.addanotherformat, locationText)}
          </label>
        </div>

        <LocalizedButtons.Add
          disabled={isLoading || !isValid}
          onClick={performAdd}
        />

        <LocalizedButtons.Cancel disabled={isLoading} onClick={onModalClose} />
      </ModalFooter>
    </Modal>
  );
};

export default CreateLocationDialog;
