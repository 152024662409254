import React, {
  useCallback,
  useMemo,
  useContext,
  useSyncExternalStore
} from 'react';
import _ from 'lodash';

import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  ROOT_NODE_ID
} from 'ecto-common/lib/constants';

import Heading from 'ecto-common/lib/Heading/Heading';
import Button from 'ecto-common/lib/Button/Button';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import DraggableMarkerMap from 'ecto-common/lib/Map/DraggableMarkerMap';

import styles from 'js/components/EditLocation/LocationForm.module.css';
import pageStyles from 'js/components/PageStyle.module.css';
import APIGen, { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants/index';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import { nodeIsBuilding, useNode } from 'ecto-common/lib/hooks/useCurrentNode';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';

const createInfoItem = (title: string, content: React.ReactNode) => ({
  title,
  content
});

export type LocationFormData = {
  name: string;
  street: string;
  nodeTraitIds: string[];
  latitude: number;
  longitude: number;
};

interface LocationFormProps {
  formData: LocationFormData;
  onFormDataChanged(newData: Partial<LocationFormData>): void;
  location?: NodeV2ResponseModel;
  onAddNewBuilding(): void;
  onAddNewSite(): void;
  onDeleteLocation(): void;
  onEditDashboards(): void;
  onEditProcessMaps(): void;
  onEditMeteorology(): void;
  onEditNotifications(): void;
  onEditParents(): void;
  onEditIntegrations(): void;
  onEditTools(): void;
  onEditFiles(): void;
  isVirtualRootNode: boolean;
}

type LocationButtonType = {
  onClick: () => void;
  text: string;
  icon: React.ReactNode;
  isGrey?: boolean;
};

const NodeTraitsInfo = ({ nodeId }: { nodeId: string }) => {
  const nodeQuery = useNode(nodeId);
  const nodeTraitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  const tags = useMemo(() => {
    return _.compact(
      _.map(nodeQuery.node?.nodeTraitIds, (nodeTraitId) => {
        return _.find(
          nodeTraitsQuery.data,
          (nodeTrait) => nodeTrait.id === nodeTraitId
        )?.name;
      })
    );
  }, [nodeQuery.node, nodeTraitsQuery.data]);

  return (
    <div style={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      <TagsGroup tags={tags} />
    </div>
  );
};

const LocationForm = ({
  formData,
  onFormDataChanged,
  location,
  onAddNewBuilding,
  onAddNewSite,
  onDeleteLocation,
  onEditDashboards,
  onEditProcessMaps,
  onEditMeteorology,
  onEditNotifications,
  onEditParents,
  onEditTools,
  onEditIntegrations,
  onEditFiles,
  isVirtualRootNode
}: LocationFormProps) => {
  const { tenantResources } = useContext(TenantContext);
  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  const onUpdateName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFormDataChanged({ name: event.target.value });
    },
    [onFormDataChanged]
  );

  const updateStreet = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFormDataChanged({ street: event.target.value });
    },
    [onFormDataChanged]
  );

  const onCoordinateChanged = useCallback(
    (latitude: number, longitude: number) => {
      onFormDataChanged({ latitude, longitude });
    },
    [onFormDataChanged]
  );

  const isRootNode = location.nodeId.startsWith(ROOT_NODE_ID);

  const isBuilding = nodeIsBuilding(location);

  const createButtonRow = useCallback(
    (button: LocationButtonType, buttonIndex: number) => {
      const { onClick, text, icon, isGrey } = button;

      return (
        <tr key={'row' + buttonIndex}>
          <td colSpan={2}>
            {(isGrey === true && (
              <GreyButton compact key={buttonIndex} onClick={onClick}>
                {icon}
                {text}
              </GreyButton>
            )) || (
              <Button compact key={buttonIndex} onClick={onClick}>
                {icon}
                {text}
              </Button>
            )}
          </td>
        </tr>
      );
    },
    []
  );

  const sharedButtons: LocationButtonType[] = useMemo(
    () =>
      _.compact([
        featureFlagState.integrations && {
          onClick: onEditIntegrations,
          text: T.admin.integrations.managepoints,
          icon: <Icons.Data />
        },
        hasAccessToResource(ResourceType.USER_MANAGEMENT, tenantResources) && {
          onClick: onEditNotifications,
          text: T.admin.editlocation.editnotifications.button,
          icon: <Icons.Notification />
        },
        {
          onClick: onEditParents,
          text: T.admin.editlocation.editparents.button,
          icon: <Icons.Parents />
        },
        {
          onClick: onEditMeteorology,
          text: T.admin.editlocation.editmeteorology,
          icon: <Icons.Weather />
        },
        {
          onClick: onEditDashboards,
          text: T.admin.dashboardcollection.selectdashboards,
          icon: <Icons.Dashboard />
        },
        {
          onClick: onEditProcessMaps,
          text: T.admin.editlocation.editprocessmap,
          icon: <Icons.Settings />
        },
        {
          onClick: onEditFiles,
          text: T.common.selectfiles,
          icon: <Icons.File />
        },

        {
          onClick: onDeleteLocation,
          text: isBuilding
            ? T.admin.editbuilding.deletelocation.title
            : T.admin.editsite.deletelocation.title,
          icon: <Icons.Delete />,
          isGrey: true
        }
      ]),
    [
      featureFlagState.integrations,
      onEditIntegrations,
      tenantResources,
      onEditNotifications,
      onEditParents,
      onEditMeteorology,
      onEditDashboards,
      onEditProcessMaps,
      onEditFiles,
      onDeleteLocation,
      isBuilding
    ]
  );

  const buildingButtons: LocationButtonType[] = useMemo(
    () => [
      {
        onClick: onEditTools,
        text: T.admin.editlocation.edittools,
        icon: <Icons.Tool />
      }
    ],
    [onEditTools]
  );

  const siteButtons: LocationButtonType[] = useMemo(
    () =>
      _.compact([
        !isVirtualRootNode && {
          onClick: onAddNewSite,
          text: T.admin.editlocation.addnewsite,
          icon: <Icons.Site />
        },
        !isVirtualRootNode && {
          onClick: onAddNewBuilding,
          text: T.admin.editlocation.addnewbuilding,
          icon: <Icons.Building />
        }
      ]),
    [isVirtualRootNode, onAddNewBuilding, onAddNewSite]
  );

  const inputItems = useMemo(
    () =>
      _.compact([
        {
          title: T.admin.editlocation.fields.name,
          content: (
            <TextInput
              autoComplete="off"
              spellCheck="false"
              wrapperClassName={styles.inputItem}
              autoFocus
              disabled={isRootNode}
              placeholder={T.admin.editlocation.placeholder.name}
              value={formData.name}
              onChange={onUpdateName}
            />
          )
        },
        !isRootNode && {
          title: T.admin.editlocation.fields.street,
          content: (
            <TextInput
              autoComplete="off"
              spellCheck="false"
              wrapperClassName={styles.inputItem}
              placeholder={T.admin.editlocation.placeholder.street}
              value={formData.street}
              onChange={updateStreet}
            />
          )
        }
      ]),
    [isRootNode, formData.name, formData.street, onUpdateName, updateStreet]
  );

  const infoItems = useMemo(
    () =>
      location &&
      _.compact([
        createInfoItem(
          T.admin.editlocation.fields.type,
          <NodeTraitsInfo nodeId={location.nodeId} />
        ),
        createInfoItem(
          T.admin.editlocation.fields.equipment,
          _.get(location, 'equipments', []).length
        ),
        createInfoItem(
          T.admin.editlocation.fields.powercontrols,
          _.get(location, 'powerControls', []).length
        ),
        createInfoItem(
          T.admin.editlocation.fields.linearoptimisations,
          _.get(location, 'linearOptimisations', []).length
        )
      ]),
    [location]
  );

  const center = useMemo(
    () => ({
      lat: formData.latitude ?? DEFAULT_LAT,
      lng: formData.longitude ?? DEFAULT_LNG
    }),
    [formData.latitude, formData.longitude]
  );

  return (
    <form autoComplete="off">
      <div className={styles.wrapper}>
        <div className={styles.mapArea}>
          <div className={styles.map}>
            <DraggableMarkerMap
              disableMarker={isRootNode}
              initialLatitude={center.lat}
              initialLongitude={center.lng}
              onCoordinateChanged={onCoordinateChanged}
            />
          </div>
        </div>

        <div className={styles.sidebarArea}>
          <Heading level={4}>{T.admin.editlocation.details}</Heading>

          <table className={styles.infoTable}>
            <tbody>
              {!isVirtualRootNode &&
                _.map(inputItems, (inputItem) => {
                  return (
                    <tr key={inputItem.title}>
                      <td className={styles.infoColumn}>
                        <label>{inputItem.title}</label>
                      </td>

                      <td>{inputItem.content}</td>
                    </tr>
                  );
                })}

              {!_.isEmpty(infoItems) && (
                <>
                  {!isVirtualRootNode &&
                    _.map(infoItems, (infoItem) => {
                      return (
                        <tr key={infoItem.title}>
                          <td className={pageStyles.minWidthColumn}>
                            <label>{infoItem.title}</label>
                          </td>
                          <td>{infoItem.content}</td>
                        </tr>
                      );
                    })}

                  <tr>
                    <td colSpan={2} />
                  </tr>

                  {isBuilding && _.map(buildingButtons, createButtonRow)}

                  {!isBuilding && _.map(siteButtons, createButtonRow)}

                  {!isRootNode && _.map(sharedButtons, createButtonRow)}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
};

export default LocationForm;
